import React, { useRef, useState } from "react";
import Button from "../helpers/Button";
import { uploadFile } from "../../utils/file.js";
import { toast } from "sonner";
import { deleteFileURL, downloadFileURL } from "../../apiRoutes/api.js";
import axios from "axios";
import { getToken } from "../../utils/tokenUtil.js";
import Loader from "../helpers/Loader.jsx";

const FileModal = ({
  modalData,
  onClose,
  fetchData,
  currentPage,
  pageSize,
}) => {
  const [loading, setLoading] = useState(false);
  const fileInputKMLRef = useRef();
  const fileInputRSRef = useRef();
  const fileInputLRSRef = useRef();
  const fileInputOnlineRef = useRef();
  const [files, setFiles] = useState({
    KMLFile: null,
    RSDWGFile: null,
    LRSDWGFile: null,
    OnlineDWGFile: null,
  });

  // Find RS DWG File
  const onlineDwgFile = modalData?.dwgFiles.find(
    (file) => file.dwgTypeId === 3
  );
  // Find RS DWG File
  const lrsDwgFile = modalData?.dwgFiles.find((file) => file.dwgTypeId === 4);
  // Find RS DWG File
  const rsDwgFile = modalData?.dwgFiles.find((file) => file.dwgTypeId === 2);

  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const onKMLUploadClick = () => {
    fileInputKMLRef.current.click();
  };
  const onRSUploadClick = () => {
    fileInputRSRef.current.click();
  };
  const onLRSUploadClick = () => {
    fileInputLRSRef.current.click();
  };
  const onOnlineUploadClick = () => {
    fileInputOnlineRef.current.click();
  };

  //handling the file to be uploaded
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file,
      }));
    }
  };

  //upload file function
  const handleUpload = async () => {
    const formData = new FormData();

    // Append additional fields
    formData.append("DistrictId", modalData.districtId);
    formData.append("BlockId", modalData.blockId);
    formData.append("MouzaId", modalData.mouzaId);
    formData.append("SheetId", modalData.sheetId);

    // Append files to FormData
    Object.keys(files).forEach((key) => {
      const file = files[key];
      if (file) {
        // formData.append("Files", file);
        // formData.append(`${key}`, file ? file?.name : "");
        formData.append(`${key}`, file ? file : "");
      }
    });

    setLoading(true);
    try {
      let response = await uploadFile(formData);
      if (response.statusCode === 200) {
        toast.success("Data Has Been Saved Successfully");
        onClose();
      } else {
        toast.error("Map Upload Failed");
      }
    } catch (error) {
      toast.error("Upload Failed:", error);
    } finally {
      fetchData(pageSize, currentPage);
      setLoading(false);
    }
  };

  //remove the file after choosing it
  const handleRemoveFile = (type) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [type]: null,
    }));
    // Clear the input field
    if (type === "KMLFile" && fileInputKMLRef.current) {
      fileInputKMLRef.current.value = "";
    }
    if (type === "RSDWGFile" && fileInputRSRef.current) {
      fileInputRSRef.current.value = "";
    }
    if (type === "LRSDWGFile" && fileInputLRSRef.current) {
      fileInputLRSRef.current.value = "";
    }
    if (type === "OnlineDWGFile" && fileInputOnlineRef.current) {
      fileInputOnlineRef.current.value = "";
    }
  };

  // delete file function
  const deleteFile = async (sheetId, fileType) => {
    const token = getToken(); // Retrieve the authentication token
    if (!token) {
      console.error("No Authentication Token Available.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.delete(
        `${deleteFileURL}/${sheetId}/${fileType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fileType]: null,
        }));
        if (fileType === "kml") {
          modalData.kmlFile.kmlFileName = null;
        }
        if (fileType === "OnlineMap") {
          onlineDwgFile.dwgFileName = null;
        }
        if (fileType === "lrs") {
          lrsDwgFile.dwgFileName = null;
        }
        if (fileType === "rs") {
          rsDwgFile.dwgFileName = null;
        }
        fetchData(pageSize, currentPage);
        toast.success(`Map Deleted Successfully`);
      } else {
        toast.error(
          `Delete Failed: ${response.data.message || "Please Try Again."}`
        );
      }
    } catch (error) {
      toast.error(
        `An Error Occurred: ${
          error.response?.data?.message || "Please Try Again."
        }`
      );
      console.error(
        `Error Deleting ${fileType} Map For Sheet Number ${modalData.sheet}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // on click delete file
  const handleDelete = (sheetId, fileType) => {
    if (!sheetId || !fileType) {
      console.warn("Sheet ID And Map Type Are Required To Delete A File.");
      return;
    }

    const confirmDelete = window.confirm(
      `Are You Sure You Want To Delete The Map For Sheet No. ${modalData.sheet}?`
    );
    if (!confirmDelete) {
      return;
    }
    if (confirmDelete) {
      deleteFile(sheetId, fileType);
    }
  };

  const handleDownload = async (sheetId, fileType, fileName) => {
    const token = getToken(); // Retrieve the authentication token
    if (!token) {
      console.error("No Authentication Token Available.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `${downloadFileURL}/${sheetId}/${fileType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Important: Specify response type as blob
        }
      );

      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        // Filename Setting
        link.setAttribute("download", fileName);

        // Append to the document body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      toast.error(
        `An Error Occurred: ${
          error.response?.data?.message || "Please Try Again."
        }`
      );
      console.error(
        `Error Downloading ${fileType} Map For Sheet Number ${modalData.sheet}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2 w-full sm:w-5/6 mx-2 sm:mx-0 h-5/6">
          <button
            onClick={onClose}
            className="place-self-end bg-slate-100 px-2 rounded-lg"
          >
            <i className="fa-solid fa-x"></i>
          </button>
          <div className="bg-slate-100 rounded-lg px-12 pt-6 pb-5 flex flex-col gap-5">
            <form>
              <div className="font-bold text-xl text-start mb-5">
                Upload Maps
              </div>

              <div>
                <div className={`sm:mt-5 mb-3`}>
                  <div className="grid grid-cols-1 sm:grid-cols-4 text-start sm:text-lg sm:font-semibold mb-2">
                    <div>District: {modalData?.district}</div>
                    <div>Block: {modalData?.block}</div>
                    <div>Mouza: {modalData?.mouza}</div>
                    <div>Sheet Number: {modalData?.sheet}</div>
                  </div>
                  <hr />

                  {/* KML File */}
                  <div className="my-5 gap-x-3 sm:grid sm:grid-cols-4 items-center">
                    <div className="sm:col-span-1">
                      <div className="text font-semibold">KML</div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                        <div>
                          <div
                            className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm"
                            onClick={onKMLUploadClick}
                          >
                            {!files.KMLFile ? (
                              <div>Choose File</div>
                            ) : (
                              <div>Reupload File</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <input
                            hidden
                            accept=".kml"
                            type="file"
                            ref={fileInputKMLRef}
                            onChange={(e) => handleFileChange(e, "KMLFile")}
                          />
                        </div>
                        <div className="flex items-center space-x-1 sm:space-x-7">
                          <div className="sm:pl-4 text-sm">
                            {files.KMLFile
                              ? files.KMLFile.name
                              : "No File Chosen"}
                          </div>
                          {files.KMLFile && (
                            <div
                              onClick={() => handleRemoveFile("KMLFile")}
                              className="text-lg cursor-pointer"
                            >
                              <i className="fa-regular fa-circle-xmark"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-1 flex space-x-4 items-center py-2 justify-between">
                      <div>
                        {modalData?.kmlFile?.kmlFileName &&
                          modalData?.kmlFile?.kmlFileName}
                      </div>
                      <div className="flex space-x-4 items-center">
                        {modalData?.kmlFile?.kmlFileName && (
                          <i
                            onClick={() =>
                              handleDownload(
                                modalData?.sheetId,
                                "kml",
                                modalData?.kmlFile?.kmlFileName
                              )
                            }
                            className="fa-solid fa-download text-blue-600 cursor-pointer"
                          ></i>
                        )}
                        {modalData?.kmlFile?.kmlFileName && (
                          <div
                            onClick={() =>
                              handleDelete(modalData?.sheetId, "kml")
                            }
                            className="cursor-pointer text-red-500"
                          >
                            <i className="fa-solid fa-trash"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Online DWG File */}
                  <div className="my-5 gap-x-3 sm:grid sm:grid-cols-4 items-center">
                    <div className="sm:col-span-1">
                      <div className="text font-semibold">DWG Online View</div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                        <div>
                          <div
                            className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm"
                            onClick={onOnlineUploadClick}
                          >
                            {!files.OnlineDWGFile ? (
                              <div>Choose File</div>
                            ) : (
                              <div>Reupload File</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <input
                            hidden
                            accept=".dwg"
                            type="file"
                            ref={fileInputOnlineRef}
                            onChange={(e) =>
                              handleFileChange(e, "OnlineDWGFile")
                            }
                          />
                        </div>
                        <div className="flex items-center space-x-1 sm:space-x-7">
                          <div className="sm:pl-4 text-sm">
                            {files.OnlineDWGFile
                              ? files.OnlineDWGFile.name
                              : "No File Chosen"}
                          </div>
                          {files.OnlineDWGFile && (
                            <div
                              onClick={() => handleRemoveFile("OnlineDWGFile")}
                              className="text-lg cursor-pointer"
                            >
                              <i className="fa-regular fa-circle-xmark"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-1 flex space-x-4 items-center py-2 justify-between">
                      <div>
                        {onlineDwgFile?.dwgFileName !== null &&
                          onlineDwgFile?.dwgFileName}
                      </div>
                      <div className="flex space-x-4 items-center">
                        {onlineDwgFile?.dwgFileName && (
                          <i
                            onClick={() =>
                              handleDownload(
                                modalData?.sheetId,
                                "OnlineMap",
                                onlineDwgFile?.dwgFileName
                              )
                            }
                            className="fa-solid fa-download text-blue-600 cursor-pointer"
                          ></i>
                        )}
                        {onlineDwgFile?.dwgFileName && (
                          <div
                            onClick={() =>
                              handleDelete(modalData?.sheetId, "OnlineMap")
                            }
                            className="cursor-pointer"
                          >
                            <i className="fa-solid fa-trash text-red-500"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* LRS DWG File */}
                  <div className="my-5 gap-x-3 sm:grid sm:grid-cols-4 items-center">
                    <div className="sm:col-span-1">
                      <div className="text font-semibold">DWG LRS</div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                        <div>
                          <div
                            className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm"
                            onClick={onLRSUploadClick}
                          >
                            {!files.LRSDWGFile ? (
                              <div>Choose File</div>
                            ) : (
                              <div>Reupload File</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <input
                            hidden
                            accept=".dwg"
                            type="file"
                            ref={fileInputLRSRef}
                            onChange={(e) => handleFileChange(e, "LRSDWGFile")}
                          />
                        </div>
                        <div className="flex items-center space-x-1 sm:space-x-7">
                          <div className="sm:pl-4 text-sm">
                            {files.LRSDWGFile
                              ? files.LRSDWGFile.name
                              : "No File Chosen"}
                          </div>
                          {files.LRSDWGFile && (
                            <div
                              onClick={() => handleRemoveFile("LRSDWGFile")}
                              className="text-lg cursor-pointer"
                            >
                              <i className="fa-regular fa-circle-xmark"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-1 flex space-x-4 items-center py-2 justify-between">
                      <div>
                        {lrsDwgFile?.dwgFileName !== null &&
                          lrsDwgFile?.dwgFileName}
                      </div>
                      <div className="flex space-x-4 items-center">
                        {lrsDwgFile?.dwgFileName && (
                          <i
                            onClick={() =>
                              handleDownload(
                                modalData?.sheetId,
                                "lrs",
                                lrsDwgFile?.dwgFileName
                              )
                            }
                            className="fa-solid fa-download text-blue-600 cursor-pointer"
                          ></i>
                        )}
                        {lrsDwgFile?.dwgFileName && (
                          <div
                            onClick={() =>
                              handleDelete(modalData?.sheetId, "lrs")
                            }
                            className="cursor-pointer"
                          >
                            <i className="fa-solid fa-trash text-red-500"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* RS DWG File */}
                  <div className="my-5 gap-x-3 sm:grid sm:grid-cols-4 items-center">
                    <div className="sm:col-span-1">
                      <div className="text font-semibold">DWG RS</div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                        <div>
                          <div
                            className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm"
                            onClick={onRSUploadClick}
                          >
                            {!files.RSDWGFile ? (
                              <div>Choose File</div>
                            ) : (
                              <div>Reupload File</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <input
                            hidden
                            accept=".dwg"
                            type="file"
                            ref={fileInputRSRef}
                            onChange={(e) => handleFileChange(e, "RSDWGFile")}
                          />
                        </div>
                        <div className="flex items-center space-x-1 sm:space-x-7">
                          <div className="sm:pl-4 text-sm">
                            {files.RSDWGFile
                              ? files.RSDWGFile.name
                              : "No File Chosen"}
                          </div>
                          {files.RSDWGFile && (
                            <div
                              onClick={() => handleRemoveFile("RSDWGFile")}
                              className="text-lg cursor-pointer"
                            >
                              <i className="fa-regular fa-circle-xmark"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-1 flex space-x-4 items-center py-2 justify-between">
                      <div>
                        {rsDwgFile?.dwgFileName !== null &&
                          rsDwgFile?.dwgFileName}
                      </div>
                      <div className="flex space-x-4 items-center">
                        {rsDwgFile && (
                          <i
                            onClick={() =>
                              handleDownload(
                                modalData?.sheetId,
                                "rs",
                                rsDwgFile?.dwgFileName
                              )
                            }
                            className="fa-solid fa-download text-blue-600 cursor-pointer"
                          ></i>
                        )}
                        {rsDwgFile?.dwgFileName && (
                          <div
                            onClick={() =>
                              handleDelete(modalData?.sheetId, "rs")
                            }
                            className="cursor-pointer"
                          >
                            <i className="fa-solid fa-trash text-red-500"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="place-self-end">
              <button
                type="submit"
                onClick={handleUpload}
                className={`text-lg font-semibold -mr-1 rounded-lg ${
                  files.KMLFile ||
                  files.RSDWGFile ||
                  files.LRSDWGFile ||
                  files.OnlineDWGFile
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                disabled={
                  files?.KMLFile?.name ||
                  files?.RSDWGFile?.name ||
                  files?.LRSDWGFile?.name ||
                  files?.OnlineDWGFile?.name
                    ? false
                    : true
                }
              >
                <Button
                  name="Save"
                  color="bg-green-600"
                  hover="hover:bg-green-500"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileModal;
