import CryptoJS from "crypto-js";

const SECRET_KEY = "i-land-surveyor"; // Consider using an environment variable for security in production

export const encrypt = (data) => {
  if (typeof data !== "object" || data === null) {
    console.error("Data to be encrypted must be a non-null object.");
    return null; // Handle cases where the data is not an object
  }

  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decrypt = (ciphertext) => {
  // Check if ciphertext is provided
  if (!ciphertext) {
    console.error("No ciphertext provided for decryption.");
    return null; // Return null if no ciphertext
  }

  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

  // Check if decrypted text is empty
  if (!decryptedText) {
    console.error(
      "Decrypted text is empty. Decryption may have failed or the data may be corrupt."
    );
    return null; // Return null if decryption fails
  }

  try {
    return JSON.parse(decryptedText);
  } catch (error) {
    return null; // Handle JSON parsing errors
  }
};
