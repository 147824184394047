import React, { useEffect, useState } from "react";
import { token } from "../../apiRoutes/api";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/tokenUtil";
import Button from "../helpers/Button";
import Loader from "../helpers/Loader";

const SearchBox = ({
  onSearchDistrict,
  onSearchBlock,
  onSearchMouza,
  onSearchSheet,
  check1,
  check2,
  check3,
  check4,
  tabSwitch
}) => {
  const navigate = useNavigate();

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [inputValue4, setInputValue4] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputValue1("");
    setInputValue2("");
    setInputValue3("");
    setInputValue4("");
  }, [tabSwitch])
  
  const handleChange1 = (e) => {
    setInputValue1(e.target.value);
  };
  const handleChange2 = (e) => {
    setInputValue2(e.target.value);
  };
  const handleChange3 = (e) => {
    setInputValue3(e.target.value);
  };
  const handleChange4 = (e) => {
    setInputValue4(e.target.value);
  };
  const handleCloseDistrict = () => {
    setInputValue1("");
  };
  const handleCloseBlock = () => {
    setInputValue2("");
  };
  const handleCloseMouza = () => {
    setInputValue3("");
  };
  const handleCloseSheet = () => {
    setInputValue4("");
  };

  const handleSearch = async () => {
    setLoading(true); // Set loading state to true when search starts
    try {
      const setToken = getToken();
      if (!setToken) {
        return;
      }

      const params = {
        district: inputValue1 || "",
        block: inputValue2 || "",
        mouza: inputValue3 || "",
        sheet: inputValue4 || "",
      };

      await Promise.all([
        onSearchDistrict("district", params.district),
        onSearchBlock("block", params.block),
        onSearchMouza("mouza", params.mouza),
        onSearchSheet("sheet", params.sheet),
      ]);
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error in setting up the request:", error.message);
      }
    } finally {
      setLoading(false); // Set loading state back to false when search is completed
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="sm:grid grid-cols-9 justify-between bg-slate-300 mx-3 rounded-lg px-3 py-3 items-center gap-3 max-w-full">
          {check1 && (
            <div className="col-span-2 mb-3 sm:mb-0 sm:mr-5 space-y-1">
              <div>District</div>
              <div className="flex items-center">
                <input
                  id="districtId"
                  type="text"
                  value={inputValue1}
                  onChange={handleChange1}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div
                  onClick={handleCloseDistrict}
                  className="text-lg -ml-6 cursor-pointer"
                >
                  <i className="fa-regular fa-circle-xmark"></i>
                </div>
              </div>
            </div>
          )}

          {check2 && (
            <div className="col-span-2 mb-3 sm:mb-0 sm:mr-5 space-y-1">
              <div>Block</div>
              <div className="flex items-center">
                <input
                  id="blockId"
                  type="text"
                  value={inputValue2}
                  onChange={handleChange2}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div
                  onClick={handleCloseBlock}
                  className="text-lg -ml-6 cursor-pointer"
                >
                  <i className="fa-regular fa-circle-xmark"></i>
                </div>
              </div>
            </div>
          )}

          {check3 && (
            <div className="col-span-2 mb-3 sm:mb-0 sm:mr-5 space-y-1">
              <div>Mouza</div>
              <div className="flex items-center">
                <input
                  id="mouzaId"
                  type="text"
                  value={inputValue3}
                  onChange={handleChange3}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div
                  onClick={handleCloseMouza}
                  className="text-lg -ml-6 cursor-pointer"
                >
                  <i className="fa-regular fa-circle-xmark"></i>
                </div>
              </div>
            </div>
          )}

          {check4 && (
            <div className="col-span-2 mb-3 sm:mb-0 sm:mr-5 space-y-1">
              <div>Sheet Number</div>
              <div className="flex items-center">
                <input
                  id="sheetId"
                  type="text"
                  value={inputValue4}
                  onChange={handleChange4}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div
                  onClick={handleCloseSheet}
                  className="text-lg -ml-6 cursor-pointer"
                >
                  <i className="fa-regular fa-circle-xmark"></i>
                </div>
              </div>
            </div>
          )}

          <div
            onClick={handleSearch}
            className="min-w-auto mb-3 sm:mb-0 place-self-end cursor-pointer text-center"
          >
            <Button
              name="Search"
              color="bg-[#0866ff]"
              hover="hover:bg-[#0875ff]"
              disabled={loading} // Disable the button while loading
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
