import React from 'react'
import Footer from "../Footer";
import { useLocation } from 'react-router-dom';

const FooterAccess = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/dashboard" ||
      location.pathname === "/customers" ||
      location.pathname === "/orders" ||
      location.pathname === "/properties" ||
      location.pathname === "/plotmanagement" ||
      location.pathname === "/feemanagement" ||
      location.pathname === "/autocad" ||
      location.pathname === "/whatsapp" ||
      location.pathname === "/email" ||
      location.pathname === "/razorpay" ||
      location.pathname === "/localization" ||
      location.pathname === "/profile" ? (
        <Footer />
      ) : null}
    </>
  );
}

export default FooterAccess
