import React from "react";
import { useLocation } from "react-router-dom";
import { token } from "../apiRoutes/api";

const Footer = () => {
  const location = useLocation();
  return (
    <div>
      {sessionStorage.getItem(token) ? (
        <footer
          className={`${location.pathname==="/login" && "hidden" } bg-slate-800 text-white pt-3 pl-5 pr-5 min-w-max
      }`}
        >
          <div className="lg:flex justify-between items-center">
            <div></div>
            <div className="lg:ml-[20%]">
              <div className="text-sm font-semibold text-center">
                &copy; {new Date().getFullYear()} I Land Surveyor. All rights
                reserved.
              </div>
            </div>
            <div className="space-y-2 py-3">
              <div className="font-semibold text-center">
                Powered By Think Hat Business Solutions
              </div>
              {/* <div className="flex justify-around lg:justify-between">
                <Link to="/privacy-policy" className="hover:underline">
                  Privacy Policy
                </Link>
                <Link to="/terms-of-service" className="hover:underline">
                  Terms of Service
                </Link>
              </div> */}
            </div>
          </div>
        </footer>
      ) : null}
    </div>
  );
};

export default Footer;
