import React from 'react'
import Headings from "../components/helpers/Headings";
import useAuthRedirect from '../Hooks/useAuthRedirect';

const Localization = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();
  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      <div className="items-center flex justify-between">
        <div>
          <Headings heading="Localization" />
        </div>
      </div>
      <hr />
      <div className="text-center mt-10">In Progress</div>
    </div>
  );
};

export default Localization
