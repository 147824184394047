import axios from "axios";
import { getToken } from "../tokenUtil";

export const getCustomer = async (customerSearch, payload) => {
  const setToken = getToken();
  if (!setToken) {
    return;
  }
  try {
    const response = await axios.post(customerSearch, payload, {
      headers: {
        Authorization: `Bearer ${setToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the data:", error);
    throw error; // Re-throw the error for handling by the caller
  }
};

export const editUser = async (updateCustomer, payload) => {
  const setToken = getToken();
  if (!setToken) {
    return;
  }

  try {
    const response = await axios.post(updateCustomer, payload, {
      headers: {
        Authorization: `Bearer ${setToken}`, // Replace `yourToken` with the actual token
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Error:", error.response.data);
      throw error
    } else if (error.request) {
      // No response was received
      console.error("Error: No response received", error.request);
      throw error;
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw error;
    }
  }
};

export const createUser = async (createCustomer, payload) => {
  const setToken = getToken();
  if (!setToken) {
    return;
  }

  try {
    const response = await axios.post(createCustomer, payload, {
      headers: {
        Authorization: `Bearer ${setToken}`, // Replace `yourToken` with the actual token
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Error:", error.response.data);
      throw error;
    } else if (error.request) {
      // No response was received
      console.error("Error: No response received", error.request);
      throw error;
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw error;
    }
  }
};