import React from "react";
// import OrderGrid from "../components/grids/OrderGrid";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";
// import OrderSearch from "../components/others/OrderSearch";

const Orders = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();

  // const [hideKML, setHideKML] = useState(false);
  // const [hideOnlineDWG, setHideOnlineDWG] = useState(true);
  // const [hideLRSDWG, setHideLRSDWG] = useState(true);
  // const [hideRSDWG, setHideRSDWG] = useState(true);
  // const [hidePL, setHidePL] = useState(true);

  // const handleHideKML = () => {
  //   setHideKML(false);
  //   setHideOnlineDWG(true);
  //   setHideLRSDWG(true);
  //   setHideRSDWG(true);
  //   setHidePL(true);
  // };
  // const handleHideOnlineDWG = () => {
  //   setHideKML(true);
  //   setHideOnlineDWG(false);
  //   setHideLRSDWG(true);
  //   setHideRSDWG(true);
  //   setHidePL(true);
  // };
  // const handleHideLRSDWG = () => {
  //   setHideKML(true);
  //   setHideOnlineDWG(true);
  //   setHideLRSDWG(false);
  //   setHideRSDWG(true);
  //   setHidePL(true);
  // };
  // const handleHideRSDWG = () => {
  //   setHideKML(true);
  //   setHideOnlineDWG(true);
  //   setHideLRSDWG(true);
  //   setHideRSDWG(false);
  //   setHidePL(true);
  // };
  // const handleHidePL = () => {
  //   setHideKML(true);
  //   setHideOnlineDWG(true);
  //   setHideLRSDWG(true);
  //   setHideRSDWG(true);
  //   setHidePL(false);
  // };

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "ml-60" : "ml-0"
      }`}
    >
      <Headings heading="Orders" />
      <hr />

      {/* <div className="grid sm:grid-cols-5 font-bold mx-3 my-3 rounded-lg py-3 items-center gap-3">
        <div onClick={handleHideKML}>
          <button
            className={`hover:bg-[#0866ff] hover:text-slate-50 w-full px-6 py-2 rounded-lg ${
              !hideKML ? "bg-[#0866ff] text-slate-50" : "bg-slate-300"
            }`}
          >
            KML
          </button>
        </div>
        <div onClick={handleHideOnlineDWG}>
          <button
            className={`hover:bg-[#0866ff] hover:text-slate-50 w-full px-6 py-2 rounded-lg ${
              !hideOnlineDWG ? "bg-[#0866ff] text-slate-50" : "bg-slate-300"
            }`}
          >
            Online DWG
          </button>
        </div>
        <div onClick={handleHideLRSDWG}>
          <button
            className={`hover:bg-[#0866ff] hover:text-slate-50 w-full px-6 py-2 rounded-lg ${
              !hideLRSDWG ? "bg-[#0866ff] text-slate-50" : "bg-slate-300"
            }`}
          >
            LRS DWG
          </button>
        </div>
        <div onClick={handleHideRSDWG}>
          <button
            className={`hover:bg-[#0866ff] hover:text-slate-50 w-full px-6 py-2 rounded-lg ${
              !hideRSDWG ? "bg-[#0866ff] text-slate-50" : "bg-slate-300"
            }`}
          >
            RS DWG
          </button>
        </div>
        <div onClick={handleHidePL}>
          <button
            className={`hover:bg-[#0866ff] hover:text-slate-50 w-full px-6 py-2 rounded-lg ${
              !hidePL ? "bg-[#0866ff] text-slate-50" : "bg-slate-300"
            }`}
          >
            Property Listing
          </button>
        </div>
      </div>
      <div className="mx-3 mb-3">
        <OrderSearch />
      </div>
      <div className="overflow-x-auto">
        <div className="rounded-lg px-3 py-3 gap-3 min-w-max">
          {!hideKML && (
            <div className="mb-3">
              <OrderGrid />
            </div>
          )}
          {!hideOnlineDWG && (
            <div className="mb-3">
              <OrderGrid />
            </div>
          )}
          {!hideLRSDWG && (
            <div className="mb-3">
              <OrderGrid />
            </div>
          )}
          {!hideRSDWG && (
            <div className="mb-3">
              <OrderGrid />
            </div>
          )}
          {!hidePL && (
            <div className="mb-3">
              <OrderGrid />
            </div>
          )}
        </div>
      </div> */}
      <div className="text-center mt-10">In Progress</div>
    </div>
  );
};

export default Orders;
