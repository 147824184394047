import React, { useRef, useState } from "react";
import Button from "../helpers/Button";
import { createUser, editUser } from "../../utils/customers/customerData";
import { createCustomer, updateCustomer } from "../../apiRoutes/api";
import { toast } from "sonner";

const CustomerModal = ({ modalData, onClose, setLoading, fetchData }) => {
  const modalRef = useRef();
  const [type, setType] = useState("password");
  const [show, setShow] = useState(true);
  const currentDate = new Date();
  // Format the date as needed (e.g., YYYY-MM-DD)
  const formattedDate = currentDate.toISOString().split("T")[0];

  const [inputName, setInputName] = useState(
    modalData?.name ? modalData?.name : ""
  );
  const [inputPhone, setInputPhone] = useState(
    modalData?.phone ? modalData?.phone : ""
  );
  const [inputEmail, setInputEmail] = useState(
    modalData?.email ? modalData?.email : ""
  );
  const [inputPassword, setInputPassword] = useState("");
  const [inputActive, setInputActive] = useState(
    modalData?.isActive ? modalData?.isActive : false
  );
  const [inputKML, setInputKML] = useState(
    modalData?.allowKML ? modalData?.allowKML : false
  );
  const [inputOnlineDWG, setInputOnlineDWG] = useState(
    modalData?.allowOnlineMap ? modalData?.allowOnlineMap : false
  );
  const [inputLRSDWG, setInputLRSDWG] = useState(
    modalData?.allowLRS ? modalData?.allowLRS : false
  );
  const [inputRSDWG, setInputRSDWG] = useState(
    modalData?.allowRS ? modalData?.allowRS : false
  );

  const handleName = (e) => {
    setInputName(e.target.value);
  };
  const handlePhone = (e) => {
    setInputPhone(e.target.value);
  };
  const handleEmail = (e) => {
    setInputEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setInputPassword(e.target.value);
  };
  const handleActive = (e) => {
    setInputActive(e.target.checked);
  };
  const handleKML = (e) => {
    setInputKML(e.target.checked);
  };
  const handleOnlineDWG = (e) => {
    setInputOnlineDWG(e.target.checked);
  };
  const handleLRSDWG = (e) => {
    setInputLRSDWG(e.target.checked);
  };
  const handleRSDWG = (e) => {
    setInputRSDWG(e.target.checked);
  };

  const handleShow = () => {
    setShow(!show);
    setType(show ? "text" : "password");
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const handleCreate = async () => {
    const payload = {
      name: inputName,
      phone: inputPhone,
      email: inputEmail,
      password: inputPassword,
      dob: formattedDate,
      deviceId: "test",
      isPhoneVerified: true,
      isEmailVerified: true,
      isSubscribedForPpromotion: true,
    };

    setLoading(true);
    try {
      const result = await createUser(createCustomer, payload);

      if (result && result?.statusCode === 200) {
        toast.success("Customer Created Successfully");
        fetchData();
        onClose(); // Close the modal or perform any other action
      } else {
        toast.error(result && result?.message);
      }
    } catch (error) {
      console.error("Error Creating Customer:", error);
      toast.error("An Error Occurred. Please Try Again");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    let sourceEnum;
    if (modalData?.isActive && inputActive) {
      sourceEnum = "UpdateFromAdminPanel";
    } else if (modalData?.isActive && !inputActive) {
      sourceEnum = "Deactivate";
    } else if (!modalData?.isActive && inputActive) {
      sourceEnum = "Reactivate";
    }

    const payload = {
      name: inputName || modalData?.name,
      phone: inputPhone || modalData?.phone,
      email: inputEmail || modalData?.email,
      password: inputPassword,
      dob: modalData?.dob,
      deviceId: modalData?.deviceId,
      isPhoneVerified: modalData?.isPhoneVerified,
      isEmailVerified: modalData?.isEmailVerified,
      isSubscribedForPpromotion: modalData?.isSubscribedForPpromotion,
      allowKML: inputKML,
      allowRS: inputRSDWG,
      allowLRS: inputLRSDWG,
      allowOnlineMap: inputOnlineDWG,
      userId: modalData?.userId,
      roleId: modalData?.userRoleId,
      source: sourceEnum,
    };
    setLoading(true);
    try {
      const result = await editUser(updateCustomer, payload);

      if (result && result?.statusCode === 200) {
        toast.success("Customer Updated Successfully");
        fetchData();
        onClose(); // Close the modal or perform any other action
      } else {
        toast.error(result && result?.message);
      }
    } catch (error) {
      console.error("Error Updating Customer:", error);
      toast.error("An Error Occurred. Please Try Again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      // ref={modalRef}
      onClick={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <div className="flex flex-col gap-2 w-full sm:w-1/2 h-5/6">
        <button
          onClick={onClose}
          className="place-self-end bg-slate-100 px-2 rounded-lg"
        >
          <i className="fa-solid fa-x"></i>
        </button>
        <div className="bg-slate-100 rounded-lg px-16 pt-8 pb-5 flex flex-col gap-5 overflow-x-auto">
          <form action="">
            <div className="relative mb-3">
              <div className="">
                <div className="">
                  <div className="font-bold text-xl">Edit Customer</div>
                </div>
              </div>
            </div>
            <hr />
            <div className="relative mb-3">
              <div className="">
                <div className="">
                  <div className="font-semibold">Name</div>
                </div>
                <div className="py-2">
                  <input
                    value={inputName}
                    className="w-full rounded-sm px-3 py-2 border border-slate-400"
                    type="text"
                    name="Name"
                    autoComplete="off"
                    onChange={handleName}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="relative mb-3">
              <div className="">
                <div className="">
                  <div className="font-semibold">Phone Number</div>
                </div>
                <div className="py-2">
                  <input
                    value={inputPhone}
                    className="w-full rounded-sm px-3 py-2 border border-slate-400"
                    type="text"
                    name="phone"
                    autoComplete="off"
                    onChange={handlePhone}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="relative mb-3">
              <div className="">
                <div className="">
                  <div className="font-semibold">Email</div>
                </div>
                <div className="py-2">
                  <input
                    value={inputEmail}
                    className="w-full rounded-sm px-3 py-2 border border-slate-400"
                    type="email"
                    name="Email"
                    autoComplete="off"
                    onChange={handleEmail}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="relative mb-3">
              <div className="">
                <div className="">
                  <div className="font-semibold">Password</div>
                </div>
                <div className="py-2 grid sm:grid-cols-4 space-y-2 sm:space-y-0 items-center">
                  <input
                    value={inputPassword}
                    className="w-full rounded-sm px-3 py-2 border border-slate-400 col-span-4"
                    type={type}
                    name="Password"
                    autoComplete="off"
                    onChange={handlePassword}
                    required
                  />
                  <div
                    className="place-self-end absolute py-2 px-2 rounded-lg text-center cursor-pointer"
                    onClick={handleShow}
                  >
                    {show ? (
                      <i className="text-xl fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {modalData && (
              <div className="relative mb-3">
                <div className="flex gap-x-5">
                  <div className="">
                    <div className="font-semibold">Active</div>
                  </div>
                  <div className="my-1">
                    <input
                      checked={inputActive}
                      className="rounded-sm h-4 w-4 border border-slate-400"
                      type="checkbox"
                      name="IsActive"
                      autoComplete="off"
                      onChange={handleActive}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
            {modalData && (
              <div>
                <div className="font-semibold mb-3">Allow Maps</div>
                <hr />
                <div className="grid grid-cols-2 space-x-5 mt-3">
                  <div className="relative mb-3">
                    <div className="grid grid-cols-3 gap-x-5">
                      <div className="col-span-2">
                        <div className="">KML</div>
                      </div>
                      <div className="my-1">
                        <input
                          checked={inputKML}
                          className="rounded-sm h-4 w-4 border border-slate-400"
                          type="checkbox"
                          name="KML"
                          autoComplete="off"
                          onChange={handleKML}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative mb-3">
                    <div className="grid grid-cols-3 gap-x-5">
                      <div className="col-span-2">
                        <div className="">Online DWG</div>
                      </div>
                      <div className="my-1">
                        <input
                          checked={inputOnlineDWG}
                          className="rounded-sm h-4 w-4 border border-slate-400"
                          type="checkbox"
                          name="KML"
                          autoComplete="off"
                          onChange={handleOnlineDWG}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 space-x-5">
                  <div className="relative mb-3">
                    <div className="grid grid-cols-3 gap-x-5">
                      <div className="col-span-2">
                        <div className="">LRS DWG</div>
                      </div>
                      <div className="my-1">
                        <input
                          checked={inputLRSDWG}
                          className="rounded-sm h-4 w-4 border border-slate-400"
                          type="checkbox"
                          name="KML"
                          autoComplete="off"
                          onChange={handleLRSDWG}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative mb-3">
                    <div className="grid grid-cols-3 gap-x-5">
                      <div className="col-span-2">
                        <div className="">RS DWG</div>
                      </div>
                      <div className="my-1">
                        <input
                          checked={inputRSDWG}
                          className="rounded-sm h-4 w-4 border border-slate-400"
                          type="checkbox"
                          name="KML"
                          autoComplete="off"
                          onChange={handleRSDWG}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
          <button
            onClick={modalData ? handleEdit : handleCreate}
            className={`text-lg font-semibold rounded-lg place-self-end ${
              (!modalData &&
                (!inputName || !inputPhone || !inputEmail || !inputPassword)) ||
              (modalData && (!inputName || !inputPhone || !inputEmail))
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
            disabled={
              (!modalData &&
                (!inputName || !inputPhone || !inputEmail || !inputPassword)) ||
              (modalData && (!inputName || !inputPhone || !inputEmail))
                ? true
                : false
            }
          >
            <Button
              name="Save"
              color="bg-green-600"
              hover="hover:bg-green-500"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
