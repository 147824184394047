import React, { useEffect, useState } from "react";
import LoginAdmin from "./LoginAdmin";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Customers from "./Customers";
import Orders from "./Orders";
import Properties from "./Properties";
import Profile from "./Profile";
import Setup from "./Setup";
import FeeManagement from "./FeeManagement";
import Autocad from "./Autocad";
import Whatsapp from "./Whatsapp";
import Email from "./Email";
import Razorpay from "./Razorpay";
import Localization from "./Localization";
import { token } from "../apiRoutes/api.js";
import NavAccess from "../components/helpers/NavAccess.jsx";
import SidebarAccess from "../components/helpers/SidebarAccess.jsx";
import FooterAccess from "../components/helpers/FooterAccess.jsx";
// import Admin from "./Admin.jsx";

const Page = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    // Set the initial state based on the current window width
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
  return (
    <>
      <BrowserRouter>
        <div>
          <NavAccess isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

          <div className="flex">
            <SidebarAccess
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />

            <Routes>
              <Route
                exact
                path="/"
                element={
                  sessionStorage.getItem(token) ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                element={<Dashboard isSidebarOpen={isSidebarOpen} />}
              />
              {/* <Route
                exact
                path="/admins"
                element={<Admin isSidebarOpen={isSidebarOpen} />}
              /> */}
              <Route
                exact
                path="/customers"
                element={<Customers isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/orders"
                element={<Orders isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/properties"
                element={<Properties isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/plotmanagement"
                element={<Setup isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/profile"
                element={<Profile isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/feemanagement"
                element={<FeeManagement isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/autocad"
                element={<Autocad isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/whatsapp"
                element={<Whatsapp isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/email"
                element={<Email isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/razorpay"
                element={<Razorpay isSidebarOpen={isSidebarOpen} />}
              />
              <Route
                exact
                path="/localization"
                element={<Localization isSidebarOpen={isSidebarOpen} />}
              />
              <Route exact path="/login" element={<LoginAdmin />} />
            </Routes>
          </div>

          <FooterAccess/>
        </div>
      </BrowserRouter>
    </>
  );
};

export default Page;
