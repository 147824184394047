import React, { useEffect, useState } from "react";
import CustomerGrid from "../components/grids/CustomerGrid";
import Button from "../components/helpers/Button";
import Headings from "../components/helpers/Headings";
import CustomerModal from "../components/modals/CustomerModal";
import { getCustomer } from "../utils/customers/customerData.js";
import useAuthRedirect from "../Hooks/useAuthRedirect";
import { customerSearch, pageSize, token } from "../apiRoutes/api.js";
import { useNavigate } from "react-router-dom";

const Customers = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchPayload, setSearchPayload] = useState([]);

  const fetchData = async () => {
    const payload = {
      name: searchPayload?.name,
      phone: searchPayload?.phone,
      email: searchPayload?.email,
      userRoleId: 3,
      pageNo: currentPage,
      pageSize: pageSize,
    };
    setIsLoading(true);
    try {
      const result = await getCustomer(customerSearch, payload);

      const totalRecords = result?.data?.total;
      // Calculate total pages based on total records and page size
      if (totalRecords < 1 || totalRecords === undefined) {
        setTotalPages(1);
      } else {
        setTotalPages(Math.ceil(totalRecords / pageSize));
      }
      if (result?.data?.data) {
        setData(result);
      } else {
        setData(null);
        console.error("No data found in result.");
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        console.error("Error in setting up the request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [navigate, searchPayload, currentPage]);

  const handlePayload = (payload) => {
    setSearchPayload(payload);
    setIsLoading(true);
  };

  const handleIsLoading = (isLoading) => {
    setIsLoading(isLoading);
  };

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      <div className="items-center flex justify-between">
        <div>
          <Headings heading="Customers" />
        </div>
        <div onClick={() => setShowModal(true)} className="mr-6 cursor-pointer">
          <Button
            name="Create"
            color="bg-green-600"
            hover="hover:bg-green-500"
          />
        </div>
      </div>
      {showModal && (
        <CustomerModal
          modalData={null}
          onClose={() => setShowModal(false)}
          setLoading={handleIsLoading}
          fetchData={fetchData}
        />
      )}
      <hr />
      <div className="my-2">
        <CustomerGrid
          data={data}
          isLoading={isLoading}
          setLoading={handleIsLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          onFetch={handlePayload}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
};

export default Customers;
