import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginRoute, token } from "../apiRoutes/api.js";
import axios from "axios";
import { toast } from "sonner";
import logo from "../assets/I Land Surveyor.png";
import { UserContext } from "../context/userContext.js";
import Loader from "../components/helpers/Loader.jsx";

const LoginAdmin = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem(token)) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const [type, setType] = useState("password");
  const [show, setShow] = useState(true);
  const [values, setValues] = useState({
    phone: "",
    password: "",
  });

  const handleShow = () => {
    setShow(!show);
    setType(show ? "text" : "password");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (handleValidation()) {
      const { password, phone } = values;
      try {
        const { data } = await axios.post(loginRoute, {
          phone,
          password,
        });
        if (data?.statusCode === 200) {
          // RoleId 1 for System Admin and RoleId 2 for Admin
          if (data?.data?.roleId ===1 || data?.data?.roleId ===2) {
            sessionStorage.setItem(token, JSON.stringify(data.data.jwtToken));
            setUser({
              userId: data?.data?.id ? data?.data?.id : "",
              userName: data?.data?.name ? data?.data?.name : "",
              userPhone: data?.data?.phone ? data?.data?.phone : "",
              userEmail: data?.data?.email ? data?.data?.email : "",
            });
            toast.success("Logged In Successfully");
            navigate("/dashboard");
          } else {
            toast.error("Unauthorized Role. Access Denied");
          }
        } else {
          toast.error(data?.message);
          navigate("/login");
        }
      } catch (error) {
        toast.error("An Error Occurred. Please Try Again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleValidation = () => {
    const { password, phone } = values;
    if (!phone || !password) {
      toast.warning("Phone Number and Password Are Required.");
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div id="login">
      {!loading ? (
        <div className="w-screen flex items-center justify-center bg-blur-sm z-10 fixed inset-0">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="bg-slate-100 px-10 py-3 pb-10 mx-7 my-20 rounded-sm">
              <div className="flex my-10 justify-center items-center space-x-5">
                <div>
                  <img src={logo} alt="Logo" className="w-16" />
                </div>
                <div className=" font-bold text-2xl text-center">
                  I Land Surveyor
                </div>
              </div>
              <hr />
              <div className="my-3">
                <div className="space-y-2">
                  <div className="font-bold">Phone Number</div>
                  <input
                    required
                    className="bg-slate-50 px-2 py-2 rounded-lg w-full border border-slate-400"
                    // placeholder="Enter Phone Number"
                    type="text"
                    name="phone"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="my-3">
                <div className="space-y-2">
                  <div className="font-bold">Password</div>
                  <div className="grid sm:grid-cols-4 space-y-2 sm:space-y-0 items-center">
                    <input
                      required
                      className="col-span-4 bg-slate-50 px-2 py-2 rounded-lg w-full border border-slate-400"
                      // placeholder="Enter Password"
                      type={type}
                      name="password"
                      onChange={(e) => handleChange(e)}
                    />
                    <div
                      className="place-self-end absolute py-2 px-2 rounded-lg text-center cursor-pointer"
                      onClick={handleShow}
                    >
                      {show ? (
                        <i className="text-xl fa-regular fa-eye"></i>
                      ) : (
                        <i className="fa-regular fa-eye-slash"></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="mt-10 mb-5 text-white bg-[#0866ff] rounded-lg hover:bg-[#0875ff] transition duration-150 py-2 font-bold px-[16vw] w-full"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default LoginAdmin;
