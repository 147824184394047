import React from 'react'
import { useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar';

const SidebarAccess = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/dashboard" ||
      location.pathname === "/customers" ||
      location.pathname === "/orders" ||
      location.pathname === "/properties" ||
      location.pathname === "/plotmanagement" ||
      location.pathname === "/feemanagement" ||
      location.pathname === "/autocad" ||
      location.pathname === "/whatsapp" ||
      location.pathname === "/email" ||
      location.pathname === "/razorpay" ||
      location.pathname === "/localization" ||
      location.pathname === "/profile" ? (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : null}
    </>
  );
};

export default SidebarAccess
