import React from "react";

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
      <button
        type="button"
        className="bg-transparent text-slate-50 px-5 py-4 rounded-lg"
        disabled
      >
        <div className="animate-spin h-5 w-5">
          <i className="text-xl fa-solid fa-spinner"></i>
        </div>
      </button>
    </div>
  );
};

export default Loader;
