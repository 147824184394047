import React from 'react'

const Headings = (props) => {
  return (
    <div>
      <div className="mx-5 mt-6 mb-3 font-bold text-xl">{props.heading}</div>
    </div>
  );
}

export default Headings
