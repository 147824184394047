import React from "react";
// import Gridviewbar from "../components/grids/Gridviewbar";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";


const Dashboard = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();
  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-x-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      <div>
        <Headings heading="Dashboard" />
        <hr />
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 my-10 mx-5 lg:mx-20">
          <Gridviewbar title="Registered Customer" icon="fa-solid fa-user" />
          <Gridviewbar title="KML Files" icon="fa-solid fa-file" />
          <Gridviewbar title="DWG Files" icon="fa-solid fa-file" />
          <Gridviewbar title="Property Posted" icon="fa-solid fa-building" />
        </div> */}
        <div className="text-center mt-10">In Progress</div>
      </div>
    </div>
  );
};

export default Dashboard;
