import React from "react";
// import PropertyGrid from "../components/grids/PropertyGrid";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";

const Properties = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "ml-60" : "ml-0"
      }`}
    >
      <Headings heading="Property Listing" />
      <hr />
      {/* <div className="flex font-bold bg-slate-300 justify-between mx-3 my-3 rounded-lg px-3 py-3 items-center gap-3 max-w-full">
        <div>Listed Properties</div>
        <div>View Details</div>
      </div> */}
      {/* <div className="mx-3 my-3 rounded-lg px-3 py-3 items-center gap-3 max-w-full">
        <PropertyGrid />
        <PropertyGrid />
        <PropertyGrid />
        <PropertyGrid />
      </div> */}
      <div className="text-center mt-10">In Progress</div>
    </div>
  );
};

export default Properties;
