import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import admin from "../assets/admin.jpg";
import { token } from "../apiRoutes/api";
import { toast } from "sonner";
import logo from "../assets/I Land Surveyor.png";
import { UserContext } from "../context/userContext";

const Navbar = ({ toggleSidebar }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem(token);
    localStorage.removeItem("userData");
    toast.success("Logged Out Successfully");
    navigate("/login");
  };

  const [open, setOpen] = useState("hidden");

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen("hidden");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const handleOpen = () => {
    if (open === "hidden") {
      setOpen("");
    }
    if (open === "") {
      setOpen("hidden");
    }
  };

  return (
    <div>
      {sessionStorage.getItem(token) && (
        <nav className="bg-slate-800 text-white h-16 pt-3 pl-5 pr-5 max-w-full">
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center sm:flex-row-reverse font-bold space-x-14 sm:space-x-1">
              <i
                className="pt-1 px-3 fa-solid fa-bars cursor-pointer"
                onClick={toggleSidebar}
              ></i>
              <div className="flex items-center space-x-3">
                <div>
                  <img src={logo} alt="Logo" className="w-8" />
                </div>
                <div>
                  <Link to="/dashboard">I Land Surveyor</Link>
                </div>
              </div>
            </div>
            <div className="hidden sm:block text-center uppercase font-bold text-3xl tracking-wide">
              ADMIN PANEL
            </div>
            <div>
              <div
                onClick={handleOpen}
                className="cursor-pointer flex items-center gap-3"
              >
                <img className="w-8 h-7 rounded-full" src={admin} alt="Admin" />
                <div className="flex items-center gap-3">
                  <div className="text-base hidden sm:block">
                    Welcome: {user.userName}
                  </div>
                  <div className="hidden sm:block">
                    {open ? (
                      <i className="fa-solid fa-caret-down text-xl"></i>
                    ) : (
                      <i className="rotate-180 fa-solid fa-caret-down text-xl"></i>
                    )}
                  </div>
                </div>
              </div>
              <div
                ref={ref}
                className={`absolute ${open} right-10 mt-6 text-sm bg-slate-800 rounded-lg shadow-lg z-10`}
              >
                <Link to="/profile">
                  <div
                    onClick={() => setOpen("hidden")}
                    className="block px-6 py-3 text-white  hover:bg-[#0866ff] transition duration-300 rounded-t-lg"
                  >
                    Profile
                  </div>
                </Link>
                <Link to="/localization">
                  <div
                    onClick={() => setOpen("hidden")}
                    className="block px-6 py-3 text-white  hover:bg-[#0866ff] transition duration-300"
                  >
                    Localization
                  </div>
                </Link>
                <div
                  onClick={handleLogout}
                  className="block px-6 py-3 text-white hover:bg-[#0866ff] transition duration-300 cursor-pointer rounded-b-lg"
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
