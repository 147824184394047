import React, { useEffect, useRef, useState } from "react";
import Button from "../helpers/Button";
import { toast } from "sonner";
import axios from "axios";
import {
  createLookup,
  getLookupByType,
  getLookupByTypeByParent,
  token,
  updateLookup,
} from "../../apiRoutes/api.js";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/tokenUtil.js";
import Loader from "../helpers/Loader.jsx";

const SetupModal = ({
  open1,
  open2,
  open3,
  open4,
  modalData,
  onClose,
  fetchData,
  currentPage,
  pageSize
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  //Set the options for selections
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);

  //Set the values for input fields and Selection fields
  const [selectedOption1, setSelectedOption1] = useState(
    modalData?.district || ""
  );
  const [selectedOption1a, setSelectedOption1a] = useState(
    modalData?.district || ""
  );
  const [selectedOption2, setSelectedOption2] = useState(
    modalData?.block || ""
  );
  const [selectedOption2a, setSelectedOption2a] = useState(
    modalData?.block || ""
  );
  const [selectedOption3, setSelectedOption3] = useState(
    modalData?.mouza || ""
  );
  const [selectedOption3a, setSelectedOption3a] = useState(
    modalData?.mouza || ""
  );
  const [selectedOption4, setSelectedOption4] = useState(
    modalData?.sheet || ""
  );

  //Handling the onchange for input and Select fieldse
  const handleChange1 = (e) => {
    setSelectedOption1(e.target.value);
    setSelectedOption2("");
    setOptions2([]);
    setSelectedOption3("");
    setOptions3([]);
  };
  const handleChange1a = (e) => {
    setSelectedOption1a(e.target.value);
  };
  const handleChange2 = (e) => {
    setSelectedOption2(e.target.value);
    setSelectedOption3("");
    setOptions3([]);
  };
  const handleChange2a = (e) => {
    setSelectedOption2a(e.target.value);
  };
  const handleChange3 = (e) => {
    setSelectedOption3(e.target.value);
  };
  const handleChange3a = (e) => {
    setSelectedOption3a(e.target.value);
  };
  const handleChange4 = (e) => {
    setSelectedOption4(e.target.value);
  };

  //modal close
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  // Populate select options for district
  useEffect(() => {
    const fetchSearchData = async (type) => {
      setIsLoading(true);
      try {
        const setToken = getToken();
        if (!setToken) {
          return;
        }
        const response = await axios.get(`${getLookupByType}/${type}`, {
          headers: {
            Authorization: `Bearer ${setToken}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          const seen = new Set();
          const data = response?.data?.data.filter((e) => {
            const duplicate = seen.has(e.lookup && e.lookup);
            seen.add(e.lookup);
            return !duplicate;
          });

          switch (type) {
            case "district":
              setOptions1(data);
              break;
            // case "block":
            //   setOptions2(data);
            //   break;
            // case "mouza":
            //   setOptions3(data);
            //   break;
            default:
              break;
          }
        } else {
          console.error("Failed to fetch data for type:", type);
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("Server responded with an error:", error.response.data);
          if (error.response.status === 401) {
            sessionStorage.removeItem(token);
            navigate("/login");
          } else {
            alert(
              `Error: ${error.response.data.message || error.response.status}`
            );
          }
        } else if (error.request) {
          // Request was made but no response received
          console.error("No response received:", error.request);
        } else {
          // Something else caused the error
          console.error("Error in setting up the request:", error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (open2 || open3 || open4) {
      fetchSearchData("district");
    }
    // if ((open3 || open4) && selectedOption1) {
    //   fetchSearchData("block");
    // }
    // if (open4 && selectedOption2) {
    //   fetchSearchData("mouza");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open2, open3, open4]);

  // Populate select options for block and mouza
  const fetchSearchDataByParent = async (parent, level) => {
    setIsLoading(true);
    try {
      const setToken = getToken();
      if (!setToken) {
        return;
      }
      const response = await axios.get(
        `${getLookupByTypeByParent}/${parent}/${level}`,
        {
          headers: {
            Authorization: `Bearer ${setToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const seen = new Set();
        const data = response?.data?.data
          ? response.data.data.filter((e) => {
              const duplicate = seen.has(e.lookup && e.lookup);
              seen.add(e.lookup);
              return !duplicate;
            })
          : [];

        switch (level) {
          case "block":
            setOptions2(data);
            break;
          case "mouza":
            setOptions3(data);
            break;
          default:
            break;
        }
      } else {
        console.error("Failed to fetch data for type:", level);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        console.error("Error in setting up the request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((open3 || open4) && selectedOption1) {
      fetchSearchDataByParent(selectedOption1, "block");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption1]);
  useEffect(() => {
    if (open4 && selectedOption2) {
      fetchSearchDataByParent(selectedOption2, "mouza");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption2]);

  // useEffect(() => {
  //   if (selectedOption1) {
  //     fetchSearchDataByParent(selectedOption1, "block");
  //     setSelectedOption2("");
  //     setSelectedOption3("");
  //   }
  //   if (selectedOption2) {
  //     fetchSearchDataByParent(selectedOption2, "mouza");
  //     setSelectedOption3("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedOption1, selectedOption2]);

  // useEffect(() => {
  //   const fetchSearchDataByParent = async (parent, level) => {
  //     try {
  //       const setToken = getToken();
  //       if (!setToken) {
  //         return;
  //       }
  //       const response = await axios.get(
  //         `${getLookupByTypeByParent}/${parent}/${level}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${setToken}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         const seen = new Set();
  //         const data = response?.data?.data
  //           ? response.data.data.filter((e) => {
  //               const duplicate = seen.has(e.lookup && e.lookup);
  //               seen.add(e.lookup);
  //               return !duplicate;
  //             })
  //           : [];

  //         switch (level) {
  //           case "block":
  //             setOptions2(data);
  //             break;
  //           case "mouza":
  //             setOptions3(data);
  //             break;
  //           default:
  //             break;
  //         }
  //       } else {
  //         console.error("Failed to fetch data for type:", level);
  //       }
  //     } catch (error) {
  //       if (error.response) {
  //         // Server responded with a status other than 2xx
  //         console.error("Server responded with an error:", error.response.data);
  //         if (error.response.status === 401) {
  //           sessionStorage.removeItem(token);
  //           navigate("/login");
  //         } else {
  //           alert(
  //             `Error: ${error.response.data.message || error.response.status}`
  //           );
  //         }
  //       } else if (error.request) {
  //         // Request was made but no response received
  //         console.error("No response received:", error.request);
  //       } else {
  //         // Something else caused the error
  //         console.error("Error in setting up the request:", error.message);
  //       }
  //     }
  //   };
  //   // if ((open3 || open4) && (selectedOption1 || modalData?.district)) {
  //   //   fetchSearchDataByParent(selectedOption1 || modalData?.district, "block");
  //   // }
  //   // if (open4 && (selectedOption2 || modalData?.block)) {
  //   //   fetchSearchDataByParent(selectedOption2 || modalData?.block, "mouza");
  //   // }

  // }, [modalData, navigate, open3, open4, selectedOption1, selectedOption2]);

  // Submit Create handler function
  // const handleCreateSubmit = async () => {
  //   const setToken = getToken();
  //   if (!setToken) {
  //     return;
  //   }

  //   const postData = {
  //     districtId:
  //       options1?.find((option) => option?.lookup === selectedOption1)
  //         ?.lookupId || null,
  //     district: selectedOption1a || selectedOption1 || null,
  //     blockId:
  //       options2?.find((option) => option?.lookup === selectedOption2)
  //         ?.lookupId || null,
  //     block: selectedOption2a || selectedOption2 || null,
  //     mouzaId:
  //       options3?.find((option) => option?.lookup === selectedOption3)
  //         ?.lookupId || null,
  //     mouza: selectedOption3a || selectedOption3 || null,
  //     sheetId: null,
  //     sheetName: selectedOption4 || null,
  //   };

  //   try {
  //     const response = await axios.post(createLookup, postData, {
  //       headers: {
  //         Authorization: `Bearer ${setToken}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (response.status === 200) {
  //       fetchData();
  //       toast.success("Created successfully");
  //       onClose();
  //     } else {
  //       toast.error(
  //         `Creation failed: ${response.data.message || "Please try again."}`
  //       );
  //     }
  //   } catch (error) {
  //     console.error("API request error:", error);
  //     toast.error(
  //       `An error occurred: ${
  //         error.response?.data?.message || "Please try again."
  //       }`
  //     );
  //   }
  // };

  // Submit Create handler function
  const handleCreateSubmit = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    const postData = {
      districtId:
        options1?.find((option) => option?.lookup === selectedOption1)
          ?.lookupId || null,
      district: selectedOption1a || selectedOption1 || null,
      blockId:
        options2?.find((option) => option?.lookup === selectedOption2)
          ?.lookupId || null,
      block: selectedOption2a || selectedOption2 || null,
      mouzaId:
        options3?.find((option) => option?.lookup === selectedOption3)
          ?.lookupId || null,
      mouza: selectedOption3a || selectedOption3 || null,
      sheetId: null,
      sheetName: selectedOption4 || null,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(createLookup, postData, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        fetchData(pageSize, currentPage);
        toast.success("Data Has Been Saved Successfully");
        onClose();
      } else {
        toast.error(
          `Creation Failed: ${response.data.message || "Please Try Again."}`
        );
      }
    } catch (error) {
      console.error("API Request Error:", error);
      toast.error(
        `An Error Occurred: ${
          error.response?.data?.message || "Please Try Again."
        }`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for create button
  const handleCreate = () => {
    handleCreateSubmit();
  };

  // Submit Edit handler function
  const handleEditSubmit = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    let updateType;
    let dist;
    let blo;
    let mou;
    let she;

    if (modalData.districtId) {
      updateType = "district";
      dist = selectedOption1a;
    }
    if (modalData.blockId) {
      updateType = "block";
      dist = selectedOption1;
      blo = selectedOption2a;
    }
    if (modalData.mouzaId) {
      updateType = "mouza";
      dist = selectedOption1;
      blo = selectedOption2;
      mou = selectedOption3a;
    }
    if (modalData.sheetId) {
      updateType = "sheet";
      dist = selectedOption1;
      blo = selectedOption2;
      mou = selectedOption3;
      she = selectedOption4;
    }

    const postData = {
      districtId: modalData.districtId || null,
      district: dist || null,
      blockId: modalData.blockId || null,
      block: blo || null,
      mouzaId: modalData.mouzaId || null,
      mouza: mou || null,
      sheetId: modalData?.sheetId || null,
      sheetName: she || null,
      updateType: updateType,
    };

    // Validation logic
    if (postData.district === null) {
      postData.districtId = null;
      postData.blockId = null;
      postData.block = null;
      postData.mouzaId = null;
      postData.mouza = null;
      postData.sheetId = null;
      postData.sheetName = null;
    } else if (postData.block === null) {
      postData.blockId = null;
      postData.mouzaId = null;
      postData.mouza = null;
      postData.sheetId = null;
      postData.sheetName = null;
    } else if (postData.mouza === null) {
      postData.mouzaId = null;
      postData.sheetId = null;
      postData.sheetName = null;
    }

    // Check if validation passed
    if (postData.updateType === "district" && postData.district === null) {
      toast.error("District Is Required.");
      return;
    }
    if (
      postData.updateType === "block" &&
      (postData.district === null || postData.block === null)
    ) {
      if (postData.district === null && postData.block === null) {
        toast.error("District and Block Are Required.");
      } else if (postData.district === null) {
        toast.error("District Is Required.");
      } else if (postData.block === null) {
        toast.error("Block Is Required.");
      }

      return;
    }
    if (
      postData.updateType === "mouza" &&
      (postData.district === null ||
        postData.block === null ||
        postData.mouza === null)
    ) {
      if (
        postData.district === null &&
        postData.block === null &&
        postData.mouza === null
      ) {
        toast.error("District, Block, and Mouza Are Required.");
      } else if (postData.district === null) {
        toast.error("District Is Required.");
      } else if (postData.block === null) {
        toast.error("Block Is Required.");
      } else if (postData.mouza === null) {
        toast.error("Mouza Is Required.");
      }
      return;
    }
    if (
      postData.updateType === "sheet" &&
      (postData.district === null ||
        postData.block === null ||
        postData.mouza === null ||
        postData.sheetName === null)
    ) {
      if (
        postData.district === null &&
        postData.block === null &&
        postData.mouza === null &&
        postData.sheetName === null
      ) {
        toast.error("District, Block, Mouza, and Sheet Are Required.");
      } else if (postData.district === null) {
        toast.error("District Is Required.");
      } else if (postData.block === null) {
        toast.error("Block Is Required.");
      } else if (postData.mouza === null) {
        toast.error("Mouza Is Required.");
      } else if (postData.sheetName === null) {
        toast.error("Sheet Name Is Required.");
      }
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(updateLookup, postData, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success("Data Has Been Saved Successfully");
        fetchData(pageSize, currentPage);
        onClose();
      } else {
        toast.error(
          `Update Failed: ${response.data.message || "Please Try Again."}`
        );
      }
    } catch (error) {
      toast.error(
        `An Error Occurred: ${
          error.response?.data?.message || "Please Try Again."
        }`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for Edit button
  const handleEdit = () => {
    handleEditSubmit();
  };

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      {isLoading ? (
        <Loader/>
      ) : (
        <div className="flex flex-col gap-2 w-full sm:w-5/6 mx-2 sm:mx-0 h-5/6">
          <button
            onClick={onClose}
            className="place-self-end bg-slate-100 px-2 rounded-lg"
          >
            <i className="fa-solid fa-x"></i>
          </button>
          <div className="bg-slate-100 rounded-lg px-12 pt-6 pb-5 flex flex-col gap-5">
            <form>
              <div className="font-bold text-xl text-start mb-5">
                {open1 && !modalData ? (
                  <div>Create District</div>
                ) : open2 && !modalData ? (
                  <div>Create Block</div>
                ) : open3 && !modalData ? (
                  <div>Create Mouza</div>
                ) : open4 && !modalData ? (
                  <div>Create Sheet Number</div>
                ) : open1 && modalData ? (
                  <div>Edit District</div>
                ) : open2 && modalData ? (
                  <div>Edit Block</div>
                ) : open3 && modalData ? (
                  <div>Edit Mouza</div>
                ) : open4 && modalData ? (
                  <div>Edit Sheet Number</div>
                ) : null}
              </div>
              <hr />
              <div>
                {open1 && (
                  <div className={`relative mb-2`}>
                    <div className="">
                      <div>
                        <div className="text-lg font-semibold">District</div>
                      </div>
                      <div className="py-2">
                        <div className="">
                          <input
                            value={selectedOption1a}
                            type="text"
                            className="bg-blend-color rounded-sm min-w-full py-[6px] px-2 border border-slate-300"
                            onChange={(e) => handleChange1a(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {open2 && (
                  <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 sm:gap-10">
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">District</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption1}
                              onChange={handleChange1}
                            >
                              <option value="" disabled>
                                Select A District
                              </option>
                              {options1.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div>
                          <div className="text-lg font-semibold">Block</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <input
                              value={selectedOption2a}
                              id="block"
                              type="text"
                              className="bg-blend-color rounded-sm min-w-full py-[6px] px-2 border border-slate-300"
                              onChange={(e) => handleChange2a(e)}
                              required
                              disabled={
                                modalData || selectedOption1 ? false : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {open3 && (
                  <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 sm:gap-10">
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">District</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption1}
                              onChange={handleChange1}
                            >
                              <option value="" disabled>
                                Select A District
                              </option>
                              {options1.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">Block</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption2}
                              onChange={handleChange2}
                              disabled={
                                modalData?.block || selectedOption1
                                  ? false
                                  : true
                              }
                            >
                              <option value="">Select A Block</option>
                              {options2.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div>
                          <div className="text-lg font-semibold">Mouza</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <input
                              value={selectedOption3a}
                              id="mouza"
                              type="text"
                              className="bg-blend-color rounded-sm min-w-full py-[6px] px-2 border border-slate-300"
                              onChange={(e) => handleChange3a(e)}
                              required
                              disabled={
                                modalData || selectedOption2 ? false : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {open4 && (
                  <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 sm:gap-10">
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">District</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption1}
                              onChange={handleChange1}
                            >
                              <option value="" disabled>
                                Select A District
                              </option>
                              {options1.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">Block</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption2}
                              onChange={handleChange2}
                              disabled={
                                modalData?.block || selectedOption1
                                  ? false
                                  : true
                              }
                            >
                              <option value="">Select A Block</option>
                              {options2.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div className="">
                          <div className="text-lg font-semibold">Mouza</div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <select
                              className="w-full h-full py-2 border border-slate-300"
                              value={selectedOption3}
                              onChange={handleChange3}
                              disabled={
                                modalData?.mouza ||
                                (selectedOption1 && selectedOption2)
                                  ? false
                                  : true
                              }
                            >
                              <option value="">Select A Mouza</option>
                              {options3.map((option) => (
                                <option
                                  key={option.lookupId}
                                  value={option.lookup}
                                >
                                  {option.lookup}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`relative mb-2`}>
                      <div className="">
                        <div>
                          <div className="text-lg font-semibold">
                            Sheet Number
                          </div>
                        </div>
                        <div className="py-2 space-x-3 items-center">
                          <div className="">
                            <input
                              value={selectedOption4}
                              id="sheet"
                              type="text"
                              className="bg-blend-color rounded-sm min-w-full py-[6px] px-2 border border-slate-300"
                              onChange={(e) => handleChange4(e)}
                              disabled={
                                modalData ||
                                (selectedOption1 &&
                                  selectedOption2 &&
                                  selectedOption3)
                                  ? false
                                  : true
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>

            {!modalData ? (
              <button
                type="submit"
                onClick={handleCreate}
                disabled={
                  (selectedOption1 &&
                    selectedOption2 &&
                    selectedOption3 &&
                    selectedOption4) ||
                  selectedOption1a ||
                  selectedOption2a ||
                  selectedOption3a
                    ? false
                    : true
                }
                className={`text-lg font-semibold -mr-1 rounded-lg place-self-end ${
                  (selectedOption1 &&
                    selectedOption2 &&
                    selectedOption3 &&
                    selectedOption4) ||
                  selectedOption1a ||
                  selectedOption2a ||
                  selectedOption3a
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                } `}
              >
                <Button
                  name={isLoading ? "Loading" : "Save"}
                  color="bg-green-600"
                  hover="hover:bg-green-500"
                />
              </button>
            ) : (
              <div className="place-self-end space-x-3">
                <button
                  type="submit"
                  onClick={handleEdit}
                  disabled={
                    (open2 && selectedOption1 && selectedOption2a) ||
                    (open3 &&
                      selectedOption1 &&
                      selectedOption2 &&
                      selectedOption3a) ||
                    (open4 &&
                      selectedOption1 &&
                      selectedOption2 &&
                      selectedOption3 &&
                      selectedOption4) ||
                    (open1 && selectedOption1a)
                      ? false
                      : true
                  }
                  className={`text-lg font-semibold -mr-1 rounded-lg place-self-end ${
                    (open2 && selectedOption1 && selectedOption2a) ||
                    (open3 &&
                      selectedOption1 &&
                      selectedOption2 &&
                      selectedOption3a) ||
                    (open4 &&
                      selectedOption1 &&
                      selectedOption2 &&
                      selectedOption3 &&
                      selectedOption4) ||
                    (open1 && selectedOption1a)
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  } `}
                >
                  <Button
                    name={isLoading ? "Loading" : "Save"}
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SetupModal;
