import React, { useState } from "react";
import Button from "../helpers/Button";
import { token } from "../../apiRoutes/api";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/tokenUtil";

const CustomerSearch = ({loading, onSearch }) => {
  const navigate = useNavigate();
  const [inputName, setInputName] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const handleName = (e) => {
    setInputName(e.target.value);
  };
  const handlePhone = (e) => {
    setInputPhone(e.target.value);
  };
  const handleEmail = (e) => {
    setInputEmail(e.target.value);
  };

  const handleRemoveName = () => {
    setInputName("");
  };
  const handleRemovePhone = () => {
    setInputPhone("");
  };
  const handleRemoveEmail = () => {
    setInputEmail("");
  };

  const handleSearch = async () => {
    try {
      const setToken = getToken();
      if (!setToken) {
        return;
      }
      const payload = {
        name: inputName,
        phone: inputPhone,
        email: inputEmail,
      };

      // Call the parent's callback function with the payload
      if (onSearch) {
        await onSearch(payload);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error in setting up the request:", error.message);
      }
    }
  };

  return (
    <div>
      <div className="sm:grid grid-cols-4 justify-between bg-slate-300 mx-3 rounded-lg px-3 py-3 items-center gap-3 max-w-full">
        <div className="col-span-1 mb-3 sm:mb-0 sm:mr-5 space-y-1">
          <div>Name</div>
          <div className="flex items-center">
            <input
              id="nameId"
              type="text"
              value={inputName}
              onChange={handleName}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div
              onClick={handleRemoveName}
              className="text-lg -ml-6 cursor-pointer"
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </div>
          </div>
        </div>
        <div className="col-span-1 mb-3 sm:mb-0 sm:mr-5 space-y-1">
          <div>Phone Number</div>
          <div className="flex items-center">
            <input
              id="phoneId"
              type="text"
              value={inputPhone}
              onChange={handlePhone}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div
              onClick={handleRemovePhone}
              className="text-lg -ml-6 cursor-pointer"
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </div>
          </div>
        </div>
        <div className="col-span-1 mb-3 sm:mb-0 sm:mr-5 space-y-1">
          <div>Email</div>
          <div className="flex items-center">
            <input
              id="emailId"
              type="text"
              value={inputEmail}
              onChange={handleEmail}
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div
              onClick={handleRemoveEmail}
              className="text-lg -ml-6 cursor-pointer"
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </div>
          </div>
        </div>

        <div
          onClick={handleSearch}
          className={`min-w-auto mb-3 sm:mb-0 place-self-end ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } text-center`}
          disabled={loading}
        >
          <Button
            name="Search"
            color="bg-[#0866ff]"
            hover="hover:bg-[#0875ff]"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerSearch;
