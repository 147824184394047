export const token = process.env.REACT_APP_TOKEN;
export const host = process.env.REACT_APP_HOST;
export const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE, 10);


export const loginRoute = `${host}/api/v1/Auth/authenticate`;

export const getLookup = `${host}/api/v1/Lookup/GetPlotDetails`;
export const getLookupByType = `${host}/api/v1/Lookup/GetLookupDetailsByType`;
export const getLookupByTypeByParent = `${host}/api/v1/Lookup/GetLookupDetailsByParent&Level`;
export const getLookupBySearch = `${host}/api/v1/Lookup/GetAutoSuggestionByType`;
export const createLookup = `${host}/api/v1/Lookup/Create_District_Block_Mouza_Sheet`;
export const updateLookup = `${host}/api/v1/Lookup/Edit_District_Block_Mouza_Sheet`;
export const deleteLookup = `${host}/api/v1/Lookup/Delete_District_Block_Mouza_Sheet`;

export const uploadFileURL = `${host}/api/v1/Upload/UploadFiles`;
export const uploadExcelURL = `${host}/api/v1/Upload/BulkPlotUpload`;
export const deleteFileURL = `${host}/api/v1/Lookup/Delete_File`;
export const downloadFileURL = `${host}/api/v1/File/DownloadFile`;

export const createConfig = `${host}/api/v1/Config/CreateConfiguration`;
export const updateConfig = `${host}/api/v1/Config/UpdateConfiguration`;
export const getConfig = `${host}/api/v1/Config/GetConfiguration`;

export const customerSearch = `${host}/api/v1/User/SearchUser`;
export const updateCustomer = `${host}/api/v1/User/UpdateUser`;
export const createCustomer = `${host}/api/v1/Auth/register`;
export const changePassword = `${host}/api/v1/User/ChangePassword`;

export const wpNotification = `${host}/api/v1/Notification/WhatsApp/Send`;
export const emailNotification = `${host}/api/v1/Notification/Email/Send`;

export const getFee = `${host}/api/v1/FeeManagment/GetFeeMangemtDetails`;
export const createUpdateFee = `${host}/api/v1/FeeManagment/CreateUpdateFeeMangment`;
export const deleteFee = `${host}/api/v1/FeeManagment/DeleteeFeeManagement`;
