import React from "react";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";
// import FeemanagementGrid from "../components/grids/FeemanagementGrid";

const FeeManagement = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "ml-60" : "ml-0"
      }`}
    >
      <div className="items-center lg:flex justify-between">
        <div>
          <Headings heading="Configuration - Fee Management" />
        </div>
      </div>
      <hr />
      <div className="text-center mt-10">In Progress</div>
      {/* <div>
        <FeemanagementGrid />
      </div> */}
      {/* <div className="overflow-x-auto">
        <div className="rounded-lg px-5 py-3 items-center gap-3 min-w-max">
          <div>
            <FeemanagementGrid />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FeeManagement;
