import React, { useRef, useState } from "react";
import Button from "../helpers/Button";
import { uploadExcel } from "../../utils/excelUpload.js";
import { toast } from "sonner";
import Loader from "../helpers/Loader.jsx";
import PlotUploadTemplate from "../../assets/PlotUploadTemplate.xlsx";


const ExcelModal = ({ onClose, onFileUpload, uploadedFileName }) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();
  const [file, setFile] = useState(null);

  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const onUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    try {
      let response = await uploadExcel(formData);
      if (response.statusCode === 200) {
        onFileUpload(file?.name);
        setFile(null);
        toast.success("Data Has Been Saved Successfully");
        onClose();
      } else {
        toast.error("Map Upload Failed");
      }
    } catch (error) {
      toast.error("Upload Failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2 w-full sm:w-5/6 mx-2 sm:mx-0 h-5/6">
          <button
            onClick={onClose}
            className="place-self-end bg-slate-100 px-2 rounded-lg"
          >
            <i className="fa-solid fa-x"></i>
          </button>
          <div className="bg-slate-100 rounded-lg px-12 pt-6 pb-5 flex flex-col gap-5">
            <form>
              <div className="font-bold text-xl text-start mb-5">
                Upload Excel
              </div>
              <div className="my-5">
                <div className="text-[12px] italic">
                  <div className="font-bold">
                    Important: Excel Upload Guidelines
                  </div>
                  <div className="mb-1">
                    Before uploading your Excel file, please ensure:
                  </div>
                  <ul className="ml-5">
                    <li>
                      1. Download and review the sample Excel file to ensure
                      compatibility and correct formatting.
                    </li>
                    <li>
                      2. Column order matches the sample Excel file. The columns
                      in your file should be in the same serial order as in the
                      sample file.
                    </li>
                    <li>
                      3. Column names are identical to the sample Excel file.
                      Use the exact same column names and headers as in the
                      sample file to avoid errors.
                    </li>
                    <li>
                      4. Download sample by clicking{"  "}
                      <a
                        href={PlotUploadTemplate}
                        className="text-blue-500 underline"
                        download={"PlotUploadTemplate.xlsx"}
                      >
                        here
                      </a>
                      .
                    </li>
                    <li>
                      5. This feature is designed to create new Districts,
                      Blocks, Mouzas, and Sheet Numbers only. To ensure
                      successful creation, it is recommended to fill all
                      columns.
                    </li>
                    <li>
                      6. The Sheet Number column accepts comma-separated values.
                      For example: Sheet 1, Sheet 2, Sheet 3, etc.
                    </li>
                  </ul>
                  <div className="mt-1">
                    By following these guidelines, you'll ensure a smooth and
                    successful upload process!
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="my-5 gap-x-5 sm:grid sm:grid-cols-4 items-center">
                  <div className="sm:col-span-1">
                    <div className="text font-semibold">Select Excel</div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                      <div>
                        <div
                          className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm whitespace-nowrap"
                          onClick={onUploadClick}
                        >
                          {!file ? (
                            <div>Choose File</div>
                          ) : (
                            <div>Reupload File</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <input
                          hidden
                          accept=".xlsx, .xls"
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="flex items-center space-x-1 sm:space-x-7">
                        <div className="sm:pl-4 text-sm">
                          {file ? file.name : "No File Chosen"}
                        </div>
                        {file && (
                          <div
                            onClick={() => setFile(null)}
                            className="text-lg cursor-pointer"
                          >
                            <i className="fa-regular fa-circle-xmark"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-1 flex items-center space-x-3">
                    {uploadedFileName && (
                      <div className="">{uploadedFileName}</div>
                    )}
                    {/* {uploadedFileName && (
                      <a
                        href={uploadedFileName}
                        target="_blank"
                        className="cursor-pointer"
                        rel="noreferrer"
                        download={uploadedFileName}
                      >
                        <i className="fa-solid fa-download text-blue-600"></i>
                      </a>
                    )} */}
                    {/* {uploadedFileName && (
                      <div
                        //   onClick={() => handleDelete(modalData?.sheetId, "kml")}
                        className="cursor-pointer text-red-500"
                      >
                        <i className="fa-solid fa-trash"></i>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </form>
            <div className="place-self-end">
              <button
                type="button"
                onClick={handleUpload}
                className={`text-lg font-semibold -mr-1 rounded-lg ${
                  file ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                disabled={!file}
              >
                <Button
                  name="Save"
                  color="bg-green-600"
                  hover="hover:bg-green-500"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExcelModal;
