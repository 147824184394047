import React from "react";
import AdminForm from "../components/others/AdminForm";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";

const Profile = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();
  return (
    <div
      className={`bg-slate-100 w-full min-h-screen overflow-x-hidden ${
        isSidebarOpen ? "ml-60" : ""
      }`}
    >
      <Headings heading="Profile" />
      <hr />
      <div className={`max-w-full ${isSidebarOpen ? "ml-10" : ""}`}>
        <AdminForm />
      </div>
    </div>
  );
};

export default Profile;
