import React from "react";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  setLoading,
}) => {
  // Function to handle the Previous button click
  const handlePrevious = () => {
    if (currentPage > 1) {
      setLoading(true);
      onPageChange(currentPage - 1);
    }
  };

  // Function to handle the Next button click
  const handleNext = () => {
    if (currentPage < totalPages) {
      setLoading(true);
      onPageChange(currentPage + 1);
    }
  };

  return (
    <>
      <div className="text-end font-semibold space-x-4">
        <button
          className="disabled:bg-slate-200 disabled:cursor-not-allowed bg-slate-300 px-2 py-1 rounded-lg cursor-pointer"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="disabled:bg-slate-200 disabled:cursor-not-allowed bg-slate-300 px-2 py-1 rounded-lg cursor-pointer"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Pagination;
