import React, { useEffect, useState } from "react";
import Button from "../helpers/Button";
import axios from "axios";
import { deleteLookup, getLookup, pageSize, token } from "../../apiRoutes/api";
import SetupModal from "../modals/SetupModal";
import { getToken } from "../../utils/tokenUtil";
import { useNavigate } from "react-router-dom";
import SearchBox from "../others/SearchBox";
import { toast } from "sonner";
import FileModal from "../modals/FileModal";
import Loader from "../helpers/Loader";
import Pagination from "../helpers/Pagination";
// import ExcelModal from "../modals/ExcelModal";

const SetupGrid = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  // const [showExcelModal, setShowExcelModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //Highlighted colors setup
  const [color1, setColor1] = useState("bg-[#1705ff] text-slate-50");
  const [color2, setColor2] = useState("bg-slate-300 text-slate-900");
  const [color3, setColor3] = useState("bg-slate-300 text-slate-900");
  const [color4, setColor4] = useState("bg-slate-300 text-slate-900");

  //Set the checks when the data will be shown or hidden
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [district, setDistrict] = useState([]);
  const [block, setBlock] = useState([]);
  const [mouza, setMouza] = useState([]);
  const [sheet, setSheet] = useState([]);

  const [queryDistrict, setQueryDistrict] = useState("");
  const [queryBlock, setQueryBlock] = useState("");
  const [queryMouza, setQueryMouza] = useState("");
  const [querySheet, setQuerySheet] = useState("");

  const [typeDistrict, setTypeDistrict] = useState("district");
  const [typeBlock, setTypeBlock] = useState("block");
  const [typeMouza, setTypeMouza] = useState("mouza");
  const [typeSheet, setTypeSheet] = useState("sheet");

  const [checkCreate, setCheckCreate] = useState(true);
  const [tabSwitch, setTabSwitch] = useState(false || true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleCheck1 = () => {
    setColor1("bg-[#1705ff] text-slate-50");
    setColor2("bg-slate-300 text-slate-900");
    setColor3("bg-slate-300 text-slate-900");
    setColor4("bg-slate-300 text-slate-900");
    setCheck1(true);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setOpen1(true);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setQueryDistrict("");
    setQueryBlock("");
    setQueryMouza("");
    setQuerySheet("");
    setTabSwitch(!tabSwitch);
    setCurrentPage(1);
  };
  const handleCheck2 = () => {
    setColor1("bg-slate-300 text-slate-900");
    setColor2("bg-[#1705ff] text-slate-50");
    setColor3("bg-slate-300 text-slate-900");
    setColor4("bg-slate-300 text-slate-900");
    setCheck1(true);
    setCheck2(true);
    setCheck3(false);
    setCheck4(false);
    setOpen1(false);
    setOpen2(true);
    setOpen3(false);
    setOpen4(false);
    setQueryDistrict("");
    setQueryBlock("");
    setQueryMouza("");
    setQuerySheet("");
    setTabSwitch(!tabSwitch);
    setCurrentPage(1);
  };
  const handleCheck3 = () => {
    setColor1("bg-slate-300 text-slate-900");
    setColor2("bg-slate-300 text-slate-900");
    setColor3("bg-[#1705ff] text-slate-50");
    setColor4("bg-slate-300 text-slate-900");
    setCheck1(true);
    setCheck2(true);
    setCheck3(true);
    setCheck4(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(true);
    setOpen4(false);
    setQueryDistrict("");
    setQueryBlock("");
    setQueryMouza("");
    setQuerySheet("");
    setTabSwitch(!tabSwitch);
    setCurrentPage(1);
  };
  const handleCheck4 = () => {
    setColor1("bg-slate-300 text-slate-900");
    setColor2("bg-slate-300 text-slate-900");
    setColor3("bg-slate-300 text-slate-900");
    setColor4("bg-[#1705ff] text-slate-50");
    setCheck1(true);
    setCheck2(true);
    setCheck3(true);
    setCheck4(true);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(true);
    setQueryDistrict("");
    setQueryBlock("");
    setQueryMouza("");
    setQuerySheet("");
    setTabSwitch(!tabSwitch);
    setCurrentPage(1);
  };

  const handleSearchDistrict = (type, searchText) => {
    if (type === "district") {
      setQueryDistrict(searchText);
      setTypeDistrict(type);
      setCurrentPage(1);
    }
  };
  const handleSearchBlock = (type, searchText) => {
    if (type === "block") {
      setQueryBlock(searchText);
      setTypeBlock(type);
      setCurrentPage(1);
    }
  };
  const handleSearchMouza = (type, searchText) => {
    if (type === "mouza") {
      setQueryMouza(searchText);
      setTypeMouza(type);
      setCurrentPage(1);
    }
  };
  const handleSearchSheet = (type, searchText) => {
    if (type === "sheet") {
      setQuerySheet(searchText);
      setTypeSheet(type);
      setCurrentPage(1);
    }
  };

  //calling fetch data
  useEffect(() => {
    fetchData(pageSize, currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    queryDistrict,
    queryBlock,
    queryMouza,
    querySheet,
    check1,
    check2,
    check3,
    check4,
    currentPage,
    pageSize,
  ]);

  //fetching the data
  const fetchData = async (pageSize, pageNo) => {
    try {
      const setToken = getToken();
      if (!setToken) {
        return;
      }

      let type;
      if (check1) {
        type = typeDistrict;
      }
      if (check2) {
        type = typeBlock;
      }
      if (check3) {
        type = typeMouza;
      }
      if (check4) {
        type = typeSheet;
      }

      const payload = {
        district: queryDistrict,
        block: queryBlock,
        mouza: queryMouza,
        sheet: querySheet,
        searchTab: type,
        pageSize: pageSize,
        pageNo: pageNo,
      };

      const response = await axios.post(`${getLookup}`, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      const totalRecords = response.data.data.total;

      // Calculate total pages based on total records and page size
      if (totalRecords < 1) {
        setTotalPages(1);
      } else {
        setTotalPages(Math.ceil(totalRecords / pageSize));
      }

      if (type === typeDistrict) {
        setDistrict(response.data.data);
      } else if (type === typeBlock) {
        setBlock(response.data.data);
      } else if (type === typeMouza) {
        setMouza(response.data.data);
      } else if (type === typeSheet) {
        setSheet(response.data.data);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        console.error("Error in setting up the request:", error.message);
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  //Events on clicking the buttons
  const handleClick = (item) => {
    setCheckCreate(false);
    setModalData(item); // Set the modal data to the clicked item
    setShowModal(!showModal);
  };

  //event to handle the map button
  const handleFile = (item) => {
    setModalData(item); // Set the modal data to the clicked item
    setShowFileModal(!showFileModal);
  };

  // const handleExcel = () => {
  //   setShowExcelModal(!showExcelModal);
  // };

  const handleOpenModal = () => {
    setShowModal(true);
    setCheckCreate(true);
  };

  //delete handling
  const handleDelete = async (e) => {
    if (open1) {
      await handleDeleteSubmit(e.districtId, "district");
      // Delete all related blocks, mouzas, and sheets
      await deleteRelatedEntities(e.districtId, "district");
    } else if (open2) {
      await handleDeleteSubmit(e.blockId, "block");
      // Delete all related mouzas and sheets
      await deleteRelatedEntities(e.blockId, "block");
    } else if (open3) {
      await handleDeleteSubmit(e.mouzaId, "mouza");
      // Delete all related sheets
      await deleteRelatedEntities(e.mouzaId, "mouza");
    } else if (open4) {
      await handleDeleteSubmit(e.sheetId, "sheet");
    } else {
      console.warn("No valid state is open for deletion.");
    }
  };

  const deleteRelatedEntities = async (parentId, type) => {
    try {
      const relatedEntities = await fetchRelatedEntities(parentId, type);
      for (const entity of relatedEntities) {
        if (type === "district") {
          await handleDeleteSubmit(entity.blockId, "block");
          await deleteRelatedEntities(entity.blockId, "block");
        } else if (type === "block") {
          await handleDeleteSubmit(entity.mouzaId, "mouza");
          await deleteRelatedEntities(entity.mouzaId, "mouza");
        } else if (type === "mouza") {
          await handleDeleteSubmit(entity.sheetId, "sheet");
        }
      }
    } catch (error) {
      console.error(
        `Error deleting related entities for ${type} with ID: ${parentId}`,
        error
      );
    }
  };

  const fetchRelatedEntities = async (parentId, type) => {
    switch (type) {
      case "district":
        // Fetch blocks related to the district
        return modalData?.block.filter(
          (block) => block.modalData?.districtId === parentId
        );
      case "block":
        // Fetch mouzas related to the block
        return modalData?.mouza.filter(
          (mouza) => mouza.modalData?.blockId === parentId
        );
      case "mouza":
        // Fetch sheets related to the mouza
        return modalData?.sheet.filter(
          (sheet) => sheet.modalData?.mouzaId === parentId
        );
      default:
        console.warn("Invalid type for fetching related entities.");
        return [];
    }
  };

  const delStr = {
    district:
      "Are You Sure You Want To Delete This District? Please Note It Will Also Delete The Blocks, Mouzas and Sheet Numbers Mapped Under This District!",
    block:
      "Are You Sure You Want To Delete This Block? Please Note It Will Also Delete The Mouzas and Sheet Numbers Mapped Under This Block!",
    mouza:
      "Are You Sure You Want To Delete This Mouza? Please Note It Will Also Delete The Sheet Numbers Mapped Under This Mouza!",
    sheet: "Are You Sure You Want To Delete This Sheet Number?",
  };

  const handleDeleteSubmit = async (id, type) => {
    if (
      window.confirm(
        `${
          (open1 && delStr.district) ||
          (open2 && delStr.block) ||
          (open3 && delStr.mouza) ||
          (open4 && delStr.sheet)
        }`
      )
    ) {
      const setToken = getToken(); // Retrieve the authentication token
      if (!setToken) {
        return; // Exit if no token is available
      }

      setIsLoading(true);

      try {
        // Send a DELETE request to the server with the appropriate URL and headers
        const response = await axios.delete(`${deleteLookup}/${id}/${type}`, {
          headers: {
            Authorization: `Bearer ${setToken}`,
            "Content-Type": "application/json",
          },
        });

        // Check if the response status is 200 (success)
        if (response?.data?.statusCode === 200) {
          fetchData(pageSize, currentPage);
          toast.success("Item Deleted Successfully");
        } else {
          toast.error(
            `Delete Failed: ${response.data.message || "Please Try Again."}`
          );
        }
      } catch (error) {
        toast.error(
          `An Error Occurred: ${
            error.response?.data?.message || "Please Try Again."
          }`
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col">
          <div className="">
            <div className="my-5 mx-3 grid grid-cols-1 lg:grid-cols-5 space-y-1 sm:space-y-0 sm:space-x-5 rounded-lg">
              <div className="col-span-1">
                <div
                  onClick={handleCheck1}
                  className={`${color1} text-center cursor-pointer font-semibold px-5 py-2 rounded-lg`}
                >
                  District
                </div>
              </div>
              <div className="col-span-1">
                <div
                  onClick={handleCheck2}
                  className={`${color2} text-center cursor-pointer font-semibold px-5 py-2 rounded-lg`}
                >
                  Block
                </div>
              </div>
              <div className="col-span-1">
                <div
                  onClick={handleCheck3}
                  className={`${color3} text-center cursor-pointer font-semibold px-5 py-2 rounded-lg`}
                >
                  Mouza
                </div>
              </div>
              <div className="col-span-1">
                <div
                  onClick={handleCheck4}
                  className={`${color4} text-center cursor-pointer font-semibold px-5 py-2 rounded-lg`}
                >
                  Sheet Number
                </div>
              </div>
              <div
                onClick={handleOpenModal}
                className="col-span-1 lg:w-24 lg:place-self-end cursor-pointer text-center"
              >
                <Button
                  name="Create"
                  color="bg-green-600"
                  hover="hover:bg-green-500"
                />
              </div>
              {/* <div
                onClick={handleExcel}
                className="col-span-1 cursor-pointer text-center"
              >
                <Button
                  name="Excel"
                  color="bg-[#088395]"
                  hover="hover:bg-[#088399]"
                />
              </div> */}
            </div>

            <div className="my-3">
              <SearchBox
                tabSwitch={tabSwitch}
                onSearchDistrict={handleSearchDistrict}
                onSearchBlock={handleSearchBlock}
                onSearchMouza={handleSearchMouza}
                onSearchSheet={handleSearchSheet}
                check1={check1}
                check2={check2}
                check3={check3}
                check4={check4}
              />
            </div>

            {/* <div className="overflow-x-auto lg:overflow-x-hidden my-5 mx-3">
              <div className="my-5 flex lg:grid lg:grid-cols-5 justify-between bg-slate-300 min-w-max lg:min-w-full rounded-lg border-separate">
                {check1 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    District
                  </div>
                ) : (
                  <div></div>
                )}
                {check2 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    Block
                  </div>
                ) : (
                  <div></div>
                )}
                {check3 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    Mouza
                  </div>
                ) : (
                  <div></div>
                )}
                {check4 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    Sheet Number
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="text-end font-semibold lg:px-12 py-2 text-nowrap place-content-end">
                  Edit | Delete
                </div>
                <div></div>
              </div>

              <div className="min-w-max lg:min-w-full">
                {open1 && (
                  <div className="rounded-lg py-2 gap-x-3">
                    {district?.data ? (
                      district.data.length > 0 ? (
                        district.data.map((e) => (
                          <div
                            className="justify-between grid lg:grid-cols-5 grid-cols-2 my-3 min-w-max lg:min-w-full"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div className="hidden lg:block"></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div className="hidden lg:block"></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div className="hidden lg:block"></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}
                {open2 && (
                  <div className="my-3 rounded-lg py-2 gap-x-3">
                    {block?.data ? (
                      block.data.length > 0 ? (
                        block.data.map((e) => (
                          <div
                            className="flex items-center justify-between lg:grid lg:grid-cols-5 my-3 min-w-max lg:min-w-full"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}
                {open3 && (
                  <div className="my-3 rounded-lg py-2 gap-x-3">
                    {mouza?.data ? (
                      mouza.data.length > 0 ? (
                        mouza.data.map((e) => (
                          <div
                            className="flex items-center justify-between lg:grid lg:grid-cols-5 my-3 min-w-max lg:min-w-full"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}
                {open4 && (
                  <div className="my-3 rounded-lg py-2 gap-x-3">
                    {sheet?.data ? (
                      sheet.data.length > 0 ? (
                        sheet.data.map((e) => (
                          <div
                            className="flex items-center justify-between lg:grid lg:grid-cols-5 my-3 min-w-max lg:min-w-full"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleFile(e)}
                              >
                                <Button
                                  name="Maps"
                                  color="bg-[#340373]"
                                  hover="hover:bg-[#340399]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}
              </div>
            </div> */}
            <div className="overflow-x-auto lg:overflow-x-hidden my-5 mx-3">
              {/* Header Row */}
              <div className="my-5 grid grid-cols-5 lg:grid-cols-5 justify-between bg-slate-300 min-w-[600px] lg:min-w-full rounded-lg border-separate">
                {check1 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    District
                  </div>
                ) : (
                  <div></div>
                )}
                {check2 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    Block
                  </div>
                ) : (
                  <div></div>
                )}
                {check3 ? (
                  <div className="text-start font-semibold mx-5 py-2">
                    Mouza
                  </div>
                ) : (
                  <div></div>
                )}
                {check4 ? (
                  <div className="text-start text-nowrap font-semibold mx-5 py-2">
                    Sheet Number
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="text-end font-semibold px-5 lg:px-12 py-2 text-nowrap place-content-end">
                  Edit | Delete
                </div>
              </div>

              <div className="min-w-[600px] lg:min-w-full">
                {/* District Section */}
                {open1 && (
                  <div className="rounded-lg py-2 gap-x-3">
                    {district?.data ? (
                      district.data.length > 0 ? (
                        district.data.map((e) => (
                          <div
                            className="grid grid-cols-5 lg:grid-cols-5 my-3"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}

                {/* Block Section */}
                {open2 && (
                  <div className="rounded-lg py-2 gap-x-3">
                    {block?.data ? (
                      block.data.length > 0 ? (
                        block.data.map((e) => (
                          <div
                            className="grid grid-cols-5 lg:grid-cols-5 my-3"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}

                {/* Mouza Section */}
                {open3 && (
                  <div className="rounded-lg py-2 gap-x-3">
                    {mouza?.data ? (
                      mouza.data.length > 0 ? (
                        mouza.data.map((e) => (
                          <div
                            className="grid grid-cols-5 lg:grid-cols-5 my-3"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}

                {/* Sheet Section */}
                {open4 && (
                  <div className="rounded-lg py-2 gap-x-3">
                    {sheet?.data ? (
                      sheet.data.length > 0 ? (
                        sheet.data.map((e) => (
                          <div
                            className="grid grid-cols-5 lg:grid-cols-5 my-3"
                            key={e.id}
                          >
                            {check1 ? (
                              <div className="mx-5">{e.district}</div>
                            ) : (
                              <div></div>
                            )}
                            {check2 ? (
                              <div className="mx-5">{e.block}</div>
                            ) : (
                              <div></div>
                            )}
                            {check3 ? (
                              <div className="mx-5">{e.mouza}</div>
                            ) : (
                              <div></div>
                            )}
                            {check4 ? (
                              <div className="mx-5">{e.sheet}</div>
                            ) : (
                              <div></div>
                            )}
                            <div className="lg:flex space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 justify-end">
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleClick(e)}
                              >
                                <Button
                                  name="Edit"
                                  color="bg-[#0866ff]"
                                  hover="hover:bg-[#0890ff]"
                                />
                              </div>
                              <div
                                className="cursor-pointer text-center"
                                onClick={() => handleFile(e)}
                              >
                                <Button
                                  name="Maps"
                                  color="bg-[#340373]"
                                  hover="hover:bg-[#340399]"
                                />
                              </div>
                              <div
                                onClick={() => handleDelete(e)}
                                className="cursor-pointer text-center"
                              >
                                <Button
                                  name="Delete"
                                  color="bg-red-600"
                                  hover="hover:bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-[10%] mx-[40%]">No Data Found</div>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-auto p-4 bg-gray-100">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              loading={isLoading}
              setLoading={setIsLoading}
            />
          </div>

          {showModal && (
            <SetupModal
              open1={open1}
              open2={open2}
              open3={open3}
              open4={open4}
              modalData={!checkCreate ? modalData : null}
              onClose={handleClick}
              fetchData={fetchData}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
          {showFileModal && (
            <FileModal
              fetchData={fetchData}
              modalData={modalData}
              onClose={handleFile}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
          {/* {showExcelModal && (
            <ExcelModal
              fetchData={fetchData}
              onClose={handleExcel}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )} */}
        </div>
      )}
    </div>
  );
};

export default SetupGrid;
