import React, { useEffect, useState } from "react";
import axios from "axios";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect.js";
import Button from "../components/helpers/Button.jsx";
import {
  createConfig,
  getConfig,
  updateConfig,
  wpNotification,
} from "../apiRoutes/api.js";
import { getToken } from "../utils/tokenUtil.js";
import { toast } from "sonner";
import Loader from "../components/helpers/Loader.jsx";
import ConfigModal from "../components/modals/ConfigModal.jsx";

const Whatsapp = ({ isSidebarOpen }) => {
  useAuthRedirect();

  const [loading, setLoading] = useState(false);
  const [api, setApi] = useState("");
  const [templates, setTemplates] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [testMessage, setTestMessage] = useState("");

  const [data, setData] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    templateName: "",
    message: "",
    active: false,
  });

  const whatsappCheck = "wp";

  const handleSave = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    const settings = {
      api,
      Templates: templates,
    };
    const payload = {
      itemType: "whatsapp",
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(createConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (id) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    const settings = {
      api,
      Templates: templates,
    };

    const payload = {
      id,
      itemType: "whatsapp",
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(updateConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConfig = async (itemType) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${getConfig}/${itemType}`, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        const configData = response.data;
        setData(configData);
        // Parse the settings and update state
        if (configData?.data?.settings) {
          const settings = JSON.parse(configData?.data?.settings);
          setApi(settings?.api || "");
          setTemplates(settings?.Templates || []);
        }
      } else {
        toast.error("Error Fetching Config", response.message);
      }
    } catch (error) {
      toast.error("Error Fetching Config", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig("whatsapp");
  }, []);

  const handleTestMsg = async () => {
    const setToken = getToken();
    if (!setToken) {
      toast.error("Authorization Token Is Missing.");
      return;
    }

    const payload = {
      toNumber: phoneNumber,
      message: testMessage,
    };

    setLoading(true);
    try {
      const response = await axios.post(wpNotification, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        setPhoneNumber("");
        setTestMessage("");
        toast.success("Test Message Sent Successfully.");
      } else {
        toast.error("Error Sending Test Message: " + response?.data?.message);
      }
    } catch (error) {
      toast.error("Error Sending Test Message: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditTemplate = (templateName, message, active) => {
    // Set the template data in state and open the modal
    setModalData({ templateName, message, active });
    setShowModal(true);
  };

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="items-center flex justify-between">
            <div>
              <Headings heading="Configuration - Whatsapp" />
            </div>
          </div>
          <hr />
          <div className="grid sm:grid-cols-2 grid-cols-1 mx-5 my-5 space-y-5 sm:space-x-10 sm:space-y-0">
            <div className="space-y-5 bg-white px-5 py-5 rounded-md">
              <div className="font-bold text-lg">API Details</div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Send API</div>
                <input
                  type="text"
                  value={api}
                  onChange={(e) => setApi(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="flex justify-center">
                <div
                  onClick={
                    data?.data?.id
                      ? () => handleEdit(data?.data?.id)
                      : handleSave
                  }
                  className="lg:w-24 cursor-pointer text-center mt-5"
                >
                  <Button
                    name="Save"
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-5 bg-white px-5 py-5 rounded-md">
              <div className="font-bold text-lg">Test API</div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Phone Number</div>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Test Message</div>
                <textarea
                  value={testMessage}
                  onChange={(e) => setTestMessage(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="flex justify-center">
                <div
                  onClick={() => handleTestMsg()}
                  className="lg:w-24 cursor-pointer text-center mt-5"
                >
                  <Button
                    name="Send"
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="items-center flex justify-between">
            <div>
              <Headings heading="Templates" />
            </div>
          </div>
          <div className="mx-5 my-5 bg-white px-5 py-5 rounded-md">
            <div className="bg-slate-300 rounded-lg border-separate px-5 py-3 grid grid-cols-4 sm:grid-cols-5 mx-1 my-1 sm:space-x-5">
              <div className="sm:col-span-3 col-span-2">
                <div className="font-semibold">Name</div>
              </div>
              <div className="col-span-1">
                <div className="font-semibold">Active</div>
              </div>
              <div className="col-span-1 place-self-end">
                <div className="font-semibold">Edit</div>
              </div>
            </div>
            {templates?.map((template, index) => (
              <div
                key={index}
                className="grid grid-cols-4 sm:grid-cols-5 pl-5 mx-1 my-3 sm:space-x-3 items-center"
              >
                <div className="space-y-5 sm:col-span-3 col-span-2">
                  <div className="break-words">{template?.TemplateName}</div>
                </div>
                <div className="space-y-5 col-span-1">
                  <div>
                    {template?.Active ? (
                      <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                    ) : (
                      <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                    )}
                  </div>
                </div>
                <div className="space-y-5 col-span-1 place-self-end cursor-pointer">
                  <div
                    onClick={() =>
                      handleEditTemplate(
                        template.TemplateName,
                        template.Message,
                        template.Active
                      )
                    }
                    className=""
                  >
                    <Button
                      name="Edit"
                      color="bg-blue-600"
                      hover="hover:bg-blue-500"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {showModal && (
            <ConfigModal
              data={data}
              templateName={modalData.templateName}
              subject={null}
              message={modalData.message}
              active={modalData.active}
              onClose={() => setShowModal(false)}
              fetchConfig={fetchConfig}
              check={whatsappCheck}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Whatsapp;
