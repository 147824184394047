import React from "react";
import Navbar from "../Navbar";
import { useLocation } from "react-router-dom";

const NavAccess = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  return (
    <div>
      {location.pathname === "/dashboard" ||
      location.pathname === "/customers" ||
      location.pathname === "/orders" ||
      location.pathname === "/properties" ||
      location.pathname === "/plotmanagement" ||
      location.pathname === "/feemanagement" ||
      location.pathname === "/autocad" ||
      location.pathname === "/whatsapp" ||
      location.pathname === "/email" ||
      location.pathname === "/razorpay" ||
      location.pathname === "/localization" ||
      location.pathname === "/profile" ? (
        <Navbar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : null}
    </div>
  );
};

export default NavAccess;
