import React, { useEffect, useState } from "react";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";
import Button from "../components/helpers/Button";
import { toast } from "sonner";
import axios from "axios";
import {
  createConfig,
  emailNotification,
  getConfig,
  updateConfig,
} from "../apiRoutes/api";
import { getToken } from "../utils/tokenUtil";
import Loader from "../components/helpers/Loader";
import ConfigModal from "../components/modals/ConfigModal";

const Email = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [display, setDisplay] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [ssl, setSsl] = useState(false);
  const [defaultCred, setDefaultCred] = useState(false);
  const [templates, setTemplates] = useState([]);

  const [email, setEmail] = useState("");
  const [testMail, setTestMail] = useState("");

  const [data, setData] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    templateName: "",
    subject: "",
    message: "",
    active: false,
  });

  const emailCheck = "email";

  const handleSave = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }
    const settings = {
      address,
      display,
      host,
      port,
      user,
      password,
      ssl,
      defaultCred,
      Templates: templates,
    };
    const payload = {
      itemType: "Email",
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(createConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (id) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    const settings = {
      address,
      display,
      host,
      port,
      user,
      password,
      ssl,
      defaultCred,
      Templates: templates,
    };

    const payload = {
      id,
      itemType: "Email",
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(updateConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConfig = async (itemType) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${getConfig}/${itemType}`, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        const configData = response.data;
        setData(configData);

        // Parse the settings and update state
        if (configData?.data?.settings) {
          try {
            const settings = JSON.parse(configData?.data?.settings);
            setAddress(settings.address || "");
            setDisplay(settings.display || "");
            setHost(settings.host || "");
            setPort(settings.port || "");
            setUser(settings.user || "");
            setPassword(settings.password || "");
            setSsl(settings.ssl || false);
            setDefaultCred(settings.defaultCred || false);
            setTemplates(settings?.Templates || []);
          } catch (parseError) {
            console.error("Error parsing settings:", parseError);
            toast.error("Error Parsing Settings");
          }
        }
      } else {
        toast.error("Error Fetching Config", response.message);
      }
    } catch (error) {
      toast.error("Error Fetching Config", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig("Email");
  }, []);

  const handleTestMsg = async () => {
    const setToken = getToken();
    if (!setToken) {
      toast.error("Authorization Token Is Missing.");
      return;
    }

    const payload = {
      toEmail: email,
      mailbody: testMail,
    };

    setLoading(true);
    try {
      const response = await axios.post(emailNotification, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        setEmail("");
        setTestMail("");
        toast.success("Test Mail Sent Successfully.");
      } else {
        toast.error("Error Sending Test Mail: " + response?.data?.message);
      }
    } catch (error) {
      toast.error("Error Sending Test Mail: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditTemplate = (templateName, subject, message, active) => {
    // Set the template data in state and open the modal
    setModalData({ templateName, subject, message, active });
    setShowModal(true);
  };

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="items-center flex justify-between">
            <div>
              <Headings heading="Configuration - Email" />
            </div>
          </div>
          <hr />
          <div className="grid sm:grid-cols-2 grid-cols-1 mx-5 my-5 space-y-5 sm:space-x-10 sm:space-y-0">
            <div className="space-y-5 bg-white px-5 py-5 rounded-md">
              <div className="font-bold text-lg">Email Details</div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Email Address</div>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Email Display Name</div>
                <input
                  type="text"
                  value={display}
                  onChange={(e) => setDisplay(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Host</div>
                <input
                  type="text"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Port</div>
                <input
                  type="text"
                  value={port}
                  onChange={(e) => setPort(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">User</div>
                <input
                  type="text"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Password</div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
                {/* <button className="bg-blue-600 hover:bg-blue-500 rounded-sm text-white">
              Change Password
            </button> */}
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">SSL</div>
                <input
                  type="checkbox"
                  checked={ssl}
                  onChange={(e) => setSsl(e.target.checked)}
                  className="h-5 w-5 rounded-sm border border-slate-400 col-span-2 place-self-start"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Use Default Credentials</div>
                <input
                  type="checkbox"
                  checked={defaultCred}
                  onChange={(e) => setDefaultCred(e.target.checked)}
                  className="h-5 w-5 rounded-sm border border-slate-400 col-span-2 place-self-start"
                />
              </div>

              <div className="flex justify-center">
                <div
                  onClick={
                    data?.data?.id
                      ? () => handleEdit(data?.data?.id)
                      : handleSave
                  }
                  className="lg:w-24 cursor-pointer text-center mt-5"
                >
                  <Button
                    name="Save"
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-5 bg-white px-5 py-5 rounded-md">
              <div className="font-bold text-lg">Test Email</div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Email</div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-3">
                <div className="col-span-1">Test Mail</div>
                <textarea
                  value={testMail}
                  onChange={(e) => setTestMail(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="flex justify-center">
                <div
                  onClick={() => handleTestMsg()}
                  className="lg:w-24 cursor-pointer text-center mt-5"
                >
                  <Button
                    name="Send"
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="items-center flex justify-between">
            <div>
              <Headings heading="Templates" />
            </div>
          </div>
          <div className="mx-5 my-5 bg-white px-5 py-5 rounded-md overflow-x-auto sm:overflow-hidden max-w-screen">
            <div className="min-w-[640px]">
              <div className="bg-slate-300 rounded-lg border-separate px-5 py-3 grid grid-cols-6 mx-2 my-3 space-x-5">
                <div className="col-span-2">
                  <div className="font-semibold">Name</div>
                </div>
                <div className="col-span-2">
                  <div className="font-semibold">Subject</div>
                </div>
                <div className="col-span-1">
                  <div className="font-semibold">Active</div>
                </div>
                <div className="col-span-1 place-self-end">
                  <div className="font-semibold">Edit</div>
                </div>
              </div>
              {templates?.map((template, index) => (
                <div
                  key={index}
                  className="grid grid-cols-6 pl-5 mx-2 my-3 space-x-3 items-center"
                >
                  <div className="space-y-5 col-span-2">
                    <div className="break-words">{template?.TemplateName}</div>
                  </div>
                  <div className="space-y-5 col-span-2">
                    <div className="break-words">{template?.Subject}</div>
                  </div>
                  <div className="space-y-5 col-span-1">
                    <div>
                      {template?.Active ? (
                        <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                      ) : (
                        <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                      )}
                    </div>
                  </div>
                  <div className="space-y-5 col-span-1 place-self-end cursor-pointer">
                    <div
                      onClick={() =>
                        handleEditTemplate(
                          template.TemplateName,
                          template.Subject,
                          template.Message,
                          template.Active
                        )
                      }
                      className=""
                    >
                      <Button
                        name="Edit"
                        color="bg-blue-600"
                        hover="hover:bg-blue-500"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {showModal && (
            <ConfigModal
              data={data}
              templateName={modalData.templateName}
              subject={modalData.subject}
              message={modalData.message}
              active={modalData.active}
              onClose={() => setShowModal(false)}
              fetchConfig={fetchConfig}
              check={emailCheck}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Email;
