import { Toaster } from "sonner";
import Page from "./pages/Page";
import { UserProvider } from "../src/context/userContext.js";

function App() {
  return (
    <UserProvider>
        <div className="App">
          <Page />
          <Toaster
            theme="light"
            richColors
            position="top-center"
            duration={1500}
          />
        </div>
    </UserProvider>
  );
}

export default App;
