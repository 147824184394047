import React, { useState } from "react";
import Button from "../helpers/Button";
import Loader from "../helpers/Loader";
import CustomerModal from "../modals/CustomerModal";
import Pagination from "../helpers/Pagination";
import CustomerSearch from "../others/CustomerSearch";
import { editUser } from "../../utils/customers/customerData";
import { updateCustomer } from "../../apiRoutes/api";
import { toast } from "sonner";

const CustomerGrid = ({
  data,
  isLoading,
  setLoading,
  currentPage,
  totalPages,
  setCurrentPage,
  onFetch,
  fetchData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleSearchPayload = (payload) => {
    onFetch(payload);
  };

  const handleLoadingState = (isLoading) => {
    setLoading(isLoading);
  };

  const handleModal = (e) => {
    setShowModal(true);
    setModalData(e);
  };

  const handleRemove = async (e) => {
    if (e?.deviceId) {
      const confirmed = window.confirm(
        "Are You Sure You Want To Remove Device Id?"
      );
      if (!confirmed) {
        return; // If the user cancels, exit the function
      }
      let sourceEnum;
      if (e?.deviceId) {
        sourceEnum = "ClearDevice";
      }

      const payload = {
        userId: e?.userId,
        roleId: e?.userRoleId,
        source: sourceEnum,
      };

      setLoading(true);

      try {
        const result = await editUser(updateCustomer, payload);

        if (result && result?.statusCode === 200) {
          toast.success("Device Id Has Been Removed Successfully");
          fetchData();
        } else {
          toast.error("Error Occurred While Removing The Device Id");
        }
      } catch (error) {
        console.error("Error Removing The Device Id:", error);
        toast.error("An Error Occurred. Please Try Again");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Device Id Not Found!")
    }
  };

  return (
    <div className="">
      <div>
        <CustomerSearch loading={isLoading} onSearch={handleSearchPayload} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="overflow-x-auto lg:overflow-x-hidden">
            <div className="min-w-max lg:min-w-full">
              <div className="grid grid-cols-8 font-bold items-start bg-slate-300 mx-3 px-3 my-3 rounded-lg py-3 gap-3">
                <div className="whitespace-nowrap">Name</div>
                <div className="whitespace-nowrap">Phone Number</div>
                <div className="whitespace-nowrap">Email</div>
                <div className="whitespace-nowrap">Active</div>
                <div className="whitespace-nowrap col-span-2">
                  Allowed Maps{" "}
                  <div className="text-xs text-slate-700">
                    (KML | Online DWG | LRS DWG | RS DWG)
                  </div>
                </div>
                <div className="whitespace-nowrap text-end mr-10">Edit</div>
                <div className="whitespace-nowrap text-end mr-10">
                  Device Id
                </div>
              </div>

              {/* Data Rows */}
              <div className="mx-3 my-3 rounded-lg px-3 py-2 gap-x-5">
                {data?.data?.data ? (
                  data?.data?.data.map((e, index) => (
                    <div
                      className="grid grid-cols-8 my-3 items-center gap-3"
                      key={index}
                    >
                      <div className="break-all">{e.name}</div>
                      <div className="break-all whitespace-nowrap">
                        {e.phone}
                      </div>
                      <div className="break-all">{e.email}</div>
                      <div className="">
                        {e.isActive ? (
                          <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                        )}
                      </div>
                      <div className="col-span-2 space-x-10">
                        {e.allowKML ? (
                          <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                        )}
                        {e.allowOnlineMap ? (
                          <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                        )}
                        {e.allowLRS ? (
                          <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                        )}
                        {e.allowRS ? (
                          <i className="fa-solid fa-circle-check text-green-600 text-lg"></i>
                        ) : (
                          <i className="fa-solid fa-circle-xmark text-red-600 text-lg"></i>
                        )}
                      </div>
                      <div className="flex justify-end mx-5">
                        <div
                          onClick={() => handleModal(e)}
                          className="cursor-pointer"
                        >
                          <Button
                            name="Edit"
                            color="bg-[#0866ff]"
                            hover="hover:bg-[#0875ff]"
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mx-5">
                        <div
                          onClick={() => handleRemove(e)}
                          className="cursor-pointer"
                        >
                          <Button
                            name="Remove"
                            color="bg-red-600"
                            hover="hover:bg-red-500"
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center my-10">No Data Found</div>
                )}
              </div>

              <hr />
              <div className="mt-auto p-4 bg-gray-100">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  loading={isLoading}
                  setLoading={handleLoadingState}
                />
              </div>
              {showModal && (
                <CustomerModal
                  modalData={modalData}
                  onClose={() => setShowModal(false)}
                  setLoading={handleLoadingState}
                  fetchData={fetchData}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerGrid;
