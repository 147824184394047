import { toast } from "sonner";
import { token } from "../apiRoutes/api";

export const getToken = () => {
  const setToken = sessionStorage.getItem(token)
    ? JSON.parse(sessionStorage.getItem(token))
    : "";

  if (setToken === "") {
    toast.error("Authorization token is missing");
    return null;
  }
  return setToken;
};
