import axios from "axios";
import { uploadFileURL } from "../apiRoutes/api";
import { getToken } from "./tokenUtil";

export const uploadFile = async (data) => {
  try {
    const setToken = getToken();
    if (!setToken) {
      return;
    }
    let response = await axios.post(uploadFileURL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${setToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error while calling api", error.message);
  }
};
