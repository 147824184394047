import React from "react";

const Button = (props) => {
  return (
    <div>
      <div
        className={`${props.color} text-slate-50 ${props.hover} px-5 py-2 rounded-lg font-semibold`}
      >
        {props.name}
      </div>
    </div>
  );
};

export default Button;
