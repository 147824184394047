import React, { useMemo, useRef, useState } from "react";
import Button from "../helpers/Button";
import { createConfig } from "../../apiRoutes/api";
import { toast } from "sonner";
import JoditEditor from "jodit-react";
import { getToken } from "../../utils/tokenUtil";
import axios from "axios";
import Loader from "../helpers/Loader";

const ConfigModal = ({
  data,
  templateName,
  subject,
  message,
  active,
  onClose,
  fetchConfig,
  check,
}) => {
  const modalRef = useRef();
  const editor = useRef(null);
  // eslint-disable-next-line
  const [api, setApi] = useState("");
  // eslint-disable-next-line
  const [address, setAddress] = useState("");
  // eslint-disable-next-line
  const [display, setDisplay] = useState("");
  // eslint-disable-next-line
  const [host, setHost] = useState("");
  // eslint-disable-next-line
  const [port, setPort] = useState("");
  // eslint-disable-next-line
  const [user, setUser] = useState("");
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [ssl, setSsl] = useState(false);
  // eslint-disable-next-line
  const [defaultCred, setDefaultCred] = useState(false);

  const config = useMemo(
    () => ({
      placeholder: "Start Typing",
      hidePoweredByJodit: true,
      useSearch: true,
      spellcheck: true,
      toolbarSticky: false,
    }),
    []
  );

  const [inputBody, setInputBody] = useState(message ? message : "");
  const [inputSubject, setInputSubject] = useState(subject ? subject : "");
  const [inputActive, setInputActive] = useState(active ? active : false);
  const [loading, setLoading] = useState(false);

  const handleBody = (e) => {
    setInputBody(e.target.value);
  };
  const handleSubject = (e) => {
    setInputSubject(e.target.value);
  };
  const handleActive = (e) => {
    setInputActive(e.target.checked);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const handleEdit = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    // Parse existing templates from data
    let existingTemplates = data?.data?.settings
      ? JSON.parse(data.data.settings).Templates
      : [];

    // Check if the templateName exists in the templates array
    const existingTemplateIndex = existingTemplates.findIndex(
      (template) => template.TemplateName === templateName
    );

    if (existingTemplateIndex !== -1) {
      // If the template exists, update its body
      if (check === "email") {
        existingTemplates[existingTemplateIndex].Subject = inputSubject;
      }
      existingTemplates[existingTemplateIndex].Message = inputBody;
      existingTemplates[existingTemplateIndex].Active = inputActive;
    } else {
      // If the template doesn't exist, add it to the array
      existingTemplates.push({
        TemplateName: templateName,
        Subject: inputSubject,
        Message: inputBody,
        Active: inputActive,
      });
    }

    // Update the API settings
    const settings = {
      api:
        api || (data?.data?.settings ? JSON.parse(data.data.settings).api : ""),
      address:
        address ||
        (data?.data?.settings ? JSON.parse(data.data.settings).address : ""),
      display:
        display ||
        (data?.data?.settings ? JSON.parse(data.data.settings).display : ""),
      host:
        host ||
        (data?.data?.settings ? JSON.parse(data.data.settings).host : ""),
      port:
        port ||
        (data?.data?.settings ? JSON.parse(data.data.settings).port : ""),
      user:
        user ||
        (data?.data?.settings ? JSON.parse(data.data.settings).user : ""),
      password:
        password ||
        (data?.data?.settings ? JSON.parse(data.data.settings).password : ""),
      ssl:
        ssl ||
        (data?.data?.settings ? JSON.parse(data.data.settings).ssl : false),
      defaultCred:
        defaultCred ||
        (data?.data?.settings
          ? JSON.parse(data.data.settings).defaultCred
          : false),

      Templates: existingTemplates,
    };

    let itemType;
    if (check === "wp") {
      itemType = "whatsapp";
    } else if (check === "email") {
      itemType = "email";
    }

    const payload = {
      itemType: itemType,
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(createConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload?.itemType);
        onClose();
      } else {
        toast.error("Error Saving Settings", response?.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2 w-full sm:w-5/6">
          <button
            onClick={onClose}
            className="place-self-end bg-slate-100 px-2 rounded-lg"
          >
            <i className="fa-solid fa-x"></i>
          </button>
          <div className="bg-slate-100 rounded-lg px-16 pt-8 pb-5 flex flex-col gap-5">
            <form action="">
              <div className="relative mb-3">
                <div className="">
                  <div className="">
                    <div className="font-bold text-xl">
                      Edit {check === "email" ? "Email" : "Whatsapp"} Template
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="relative my-3">
                <div className="">
                  <div className="">
                    <div className="font-semibold">Name</div>
                  </div>
                  <div className="py-2">
                    <input
                      value={templateName}
                      disabled
                      readOnly
                      className="w-full rounded-sm px-3 py-2 border border-slate-400"
                      type="text"
                      name="Name"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
              {check === "email" && (
                <div className="relative mb-3">
                  <div className="">
                    <div className="">
                      <div className="font-semibold">Subject</div>
                    </div>
                    <div className="py-2">
                      <input
                        value={inputSubject}
                        className="w-full rounded-sm px-3 py-2 border border-slate-400"
                        type="text"
                        name="Subject"
                        autoComplete="off"
                        onChange={handleSubject}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="relative mb-3">
                <div className="">
                  <div className="">
                    <div className="font-semibold">Body</div>
                  </div>
                  <div className="py-2">
                    {check === "email" && (
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={inputBody}
                        onChange={(newInput) => setInputBody(newInput)}
                      />
                    )}
                    {check === "wp" && (
                      <textarea
                        rows={2}
                        value={inputBody}
                        className="w-full rounded-sm px-3 py-2 border border-slate-400"
                        type="text"
                        name="Body"
                        autoComplete="off"
                        onChange={handleBody}
                        required
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="relative mb-3">
                <div className="flex gap-x-5">
                  <div className="">
                    <div className="font-semibold">Active</div>
                  </div>
                  <div className="my-1">
                    <input
                      checked={inputActive}
                      className="rounded-sm h-4 w-4 border border-slate-400"
                      type="checkbox"
                      name="IsActive"
                      autoComplete="off"
                      onChange={handleActive}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
            <button
              onClick={handleEdit}
              className={`text-lg font-semibold rounded-lg place-self-end ${
                (check === "wp" && !inputBody) ||
                (check === "email" && (!inputBody || !inputSubject))
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              disabled={
                (check === "wp" && !inputBody) ||
                (check === "email" && (!inputBody || !inputSubject))
                  ? true
                  : false
              }
            >
              <Button
                name="Save"
                color="bg-green-600"
                hover="hover:bg-green-500"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigModal;
