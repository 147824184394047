import React, { useEffect, useState } from "react";
import Headings from "../components/helpers/Headings";
import useAuthRedirect from "../Hooks/useAuthRedirect";
import Button from "../components/helpers/Button";
import { toast } from "sonner";
import { getToken } from "../utils/tokenUtil";
import axios from "axios";
import { createConfig, updateConfig, getConfig } from "../apiRoutes/api";
import Loader from "../components/helpers/Loader";

const Razorpay = ({ isSidebarOpen }) => {
  //used custom hook
  useAuthRedirect();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [salt, setSalt] = useState("");
  const [merchant, setMerchant] = useState("");
  // const [logo, setLogo] = useState(null);
  // const [show, setShow] = useState(null);
  const [theme, setTheme] = useState("");
  const [emi, setEmi] = useState(false);
  const [data, setData] = useState("");
  // const fileInputRef = useRef();

  // const onUploadClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setLogo(file);
  //       // Set the logo as the base64 string
  //       setShow(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleUpload = async () => {
  //   const formData = new FormData();
  //   formData.append("file", logo);

  //   // setLoading(true);
  //   try {
  //     let response = await upload(formData);
  //     if (response.statusCode === 200) {
  //       setLogo(null);
  //       toast.success("Data Has Been Saved Successfully");
  //     } else {
  //       toast.error("Map Upload Failed");
  //     }
  //   } catch (error) {
  //     toast.error("Upload Failed:", error);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };

  const handleSave = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }
    const settings = {
      key,
      salt,
      merchant,
      theme,
      emi,
    };
    const payload = {
      itemType: "RazorPay",
      settings: JSON.stringify(settings),
    };

    setLoading(false);
    try {
      const response = await axios.post(createConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.data.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (id) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    const settings = {
      key,
      salt,
      merchant,
      theme,
      emi,
    };

    const payload = {
      id,
      itemType: "RazorPay",
      settings: JSON.stringify(settings),
    };

    setLoading(true);
    try {
      const response = await axios.post(updateConfig, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        toast.success("Settings Saved Successfully");
        fetchConfig(payload.itemType);
      } else {
        toast.error("Error Saving Settings", response.message);
      }
    } catch (error) {
      toast.error("Error Saving Settings", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConfig = async (itemType) => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${getConfig}/${itemType}`, {
        headers: {
          Authorization: `Bearer ${setToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.statusCode === 200) {
        const configData = response.data;
        setData(configData);
        // Parse the settings and update state
        if (configData?.data?.settings) {
          const settings = JSON.parse(configData.data.settings);
          setKey(settings.key || "");
          setSalt(settings.salt || "");
          setMerchant(settings.merchant || "");
          setTheme(settings.theme || "");
          setEmi(settings.emi || "");
        }
      } else {
        toast.error("Error fetching config", response.message);
      }
    } catch (error) {
      toast.error("Error fetching config", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig("RazorPay");
  }, []);

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-hidden ${
        isSidebarOpen ? "lg:ml-60" : ""
      }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="items-center flex justify-between">
            <div>
              <Headings heading="Configuration - Razorpay" />
            </div>
          </div>
          <hr />
          <div className="space-y-5 bg-white px-5 py-5 rounded-md mx-5 my-5">
            <div className="font-bold text-lg">Razorpay Details</div>
            <div className="space-y-5 my-5">
              <div className="grid sm:grid-cols-4">
                <div className="col-span-1">Key</div>
                <input
                  type="text"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-4">
                <div className="col-span-1">Salt</div>
                <input
                  type="text"
                  value={salt}
                  onChange={(e) => setSalt(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-4">
                <div className="col-span-1">Merchant Name</div>
                <input
                  type="text"
                  value={merchant}
                  onChange={(e) => setMerchant(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-4">
                <div className="col-span-1">Theme Color</div>
                <input
                  type="text"
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  className="px-2 py-1 rounded-sm col-span-2 border border-slate-400"
                />
              </div>
              <div className="grid sm:grid-cols-4">
                <div className="col-span-1">EMI</div>
                <input
                  type="checkbox"
                  checked={emi}
                  onChange={(e) => setEmi(e.target.checked)}
                  className="h-5 w-5 rounded-sm border border-slate-400 col-span-2 place-self-start"
                />
              </div>
              <div className="flex justify-center">
                <div
                  onClick={
                    data?.data?.id
                      ? () => handleEdit(data?.data?.id)
                      : handleSave
                  }
                  className="lg:w-24 cursor-pointer text-center"
                >
                  <Button
                    name="Save"
                    color="bg-green-600"
                    hover="hover:bg-green-500"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-5 my-5">
              <div className="space-y-5">
                {/* <div className="grid sm:grid-cols-4">
                  <div className="col-span-1">Upload Logo</div>
                  <div className="col-span-2 flex items-center gap-1 space-y-1 sm:space-y-0 sm:gap-5 bg-slate-300 px-2 py-1 rounded-md">
                    <div>
                      <div
                        className="cursor-pointer bg-slate-200 text-slate-600 px-2 py-1 rounded-md font-semibold text-sm whitespace-nowrap"
                        onClick={onUploadClick}
                      >
                        {!logo ? (
                          <div>Choose File</div>
                        ) : (
                          <div>Reupload File</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <input
                        hidden
                        accept=".png"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div> */}
                {/* Display the uploaded image */}
                {/* {logo && (
                  <div className="grid sm:grid-cols-4 mt-2">
                    <div className="col-span-1"></div>
                    <div className="col-span-2">
                      <img
                        src={show}
                        alt="Uploaded Logo"
                        className="max-h-40 rounded-md"
                      />
                    </div>
                  </div>
                )} */}
                {/* <div className="flex space-x-10 justify-center">
                  <button
                    // onClick={handleUpload}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500"
                  >
                    Upload
                  </button>
                  <button
                    // onClick={() => setLogo(null)}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-500"
                  >
                    Remove
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Razorpay;
