import React, { useState } from "react";
import Headings from "../components/helpers/Headings";
import SetupGrid from "../components/grids/SetupGrid";
import useAuthRedirect from "../Hooks/useAuthRedirect";
import Button from "../components/helpers/Button";
import ExcelModal from "../components/modals/ExcelModal";

const Setup = ({ isSidebarOpen }) => {
  // Used custom hook
  useAuthRedirect();

  const [showExcelModal, setShowExcelModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const handleExcel = () => {
    setShowExcelModal(!showExcelModal);
  };
  const handleFileUpload = (fileName) => {
    setUploadedFileName(fileName);
  };

  return (
    <div
      className={`bg-slate-100 min-h-screen w-full overflow-x-hidden ${
        isSidebarOpen ? "ml-60" : "ml-0"
      }`}
    >
      <div className="items-center lg:flex justify-between">
        <div>
          <Headings heading="Configuration - Plot Management" />
        </div>
        <div onClick={handleExcel} className="mx-3 cursor-pointer text-center">
          <Button
            name="Upload Bulk Plots"
            color="bg-[#e79a08]"
            hover="hover:bg-[#e79a50]"
          />
        </div>
      </div>
      <hr />
      <div>
        <SetupGrid />
      </div>
      {showExcelModal && (
        <ExcelModal
          onClose={handleExcel}
          onFileUpload={handleFileUpload}
          uploadedFileName={uploadedFileName}
        />
      )}
    </div>
  );
};

export default Setup;
