import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/I Land Surveyor.png";


const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isSetupOpen, setIsSetupOpen] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    if (window.innerWidth < 1024) {
      toggleSidebar();
    }
  };

  const handleSetupClick = () => {
    setIsSetupOpen(!isSetupOpen);
  };

  return (
    <div>
      {sessionStorage.getItem("token") && (
        <div
          className={`absolute lg:fixed z-50 inset-y-0 left-0 transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out bg-slate-800 text-white pt-3 px-4 w-full sm:w-60 min-h-screen`}
        >
          <div className="hidden sm:flex justify-between mt-2 items-center sm:flex-row-reverse font-bold space-x-14 sm:space-x-1">
            <i
              className="pt-1 px-3 fa-solid fa-bars cursor-pointer"
              onClick={toggleSidebar}
            ></i>
            <div className="flex items-center space-x-3">
              <div>
                <img src={logo} alt="Logo" className="w-8" />
              </div>
              <div>
                <Link to="/dashboard">I Land Surveyor</Link>
              </div>
            </div>
          </div>
          <div className="flex justify-end pr-4 lg:hidden mt-2 sm:mt-0">
            <i
              className="fa-solid fa-times cursor-pointer text-xl"
              onClick={toggleSidebar}
            ></i>
          </div>
          <Link to="/dashboard" onClick={handleLinkClick}>
            <div
              className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 mt-9 mb-2 ${
                location.pathname === "/dashboard" ? "bg-[#0866ff]" : ""
              }`}
            >
              Dashboard
            </div>
          </Link>
          {/* <Link to="/admins" onClick={handleLinkClick}>
            <div
              className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                location.pathname === "/admins" ? "bg-[#0866ff]" : ""
              }`}
            >
              Admins
            </div>
          </Link> */}
          <Link to="/customers" onClick={handleLinkClick}>
            <div
              className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                location.pathname === "/customers" ? "bg-[#0866ff]" : ""
              }`}
            >
              Customers
            </div>
          </Link>
          <Link to="/orders" onClick={handleLinkClick}>
            <div
              className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                location.pathname === "/orders" ? "bg-[#0866ff]" : ""
              }`}
            >
              Orders
            </div>
          </Link>
          <Link to="/properties" onClick={handleLinkClick}>
            <div
              className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                location.pathname === "/properties" ? "bg-[#0866ff]" : ""
              }`}
            >
              Property Listing
            </div>
          </Link>
          <div onClick={handleSetupClick}>
            <div className="text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 flex items-center justify-between">
              <span>Configuration</span>
              <i
                className={`fa-solid fa-caret-${
                  isSetupOpen ? "up" : "down"
                } text-xl`}
              ></i>
            </div>
          </div>
          {isSetupOpen && (
            <div className="ml-6">
              <Link to="/plotmanagement" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/plotmanagement"
                      ? "bg-[#0866ff]"
                      : ""
                  }`}
                >
                  Plot Management
                </div>
              </Link>
              <Link to="/feemanagement" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/feemanagement" ? "bg-[#0866ff]" : ""
                  }`}
                >
                  Fee Management
                </div>
              </Link>
              <Link to="/autocad" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/autocad" ? "bg-[#0866ff]" : ""
                  }`}
                >
                  Autocad
                </div>
              </Link>
              <Link to="/whatsapp" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/whatsapp" ? "bg-[#0866ff]" : ""
                  }`}
                >
                  Whatsapp
                </div>
              </Link>
              <Link to="/email" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/email" ? "bg-[#0866ff]" : ""
                  }`}
                >
                  Email
                </div>
              </Link>
              <Link to="/razorpay" onClick={handleLinkClick}>
                <div
                  className={`text-sm text-white hover:bg-[#0866ff] cursor-pointer px-3 py-2 rounded-md transition duration-300 my-2 ${
                    location.pathname === "/razorpay" ? "bg-[#0866ff]" : ""
                  }`}
                >
                  Razorpay
                </div>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
