import React, { createContext, useState, useEffect } from "react";
import { encrypt, decrypt } from "../utils/crypto.js"; // Adjust the import path accordingly
import { token } from "../apiRoutes/api.js";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    userId: "",
    userName: "",
    userPhone: "",
    userEmail: "",
  });

  useEffect(() => {
    // Check if token exists in sessionStorage
    const sessionToken = sessionStorage.getItem(token);

    // If token exists, attempt to retrieve and decrypt user data from localStorage
    if (sessionToken) {
      const storedEncryptedUserData = localStorage.getItem("userData");

      if (!storedEncryptedUserData) {
        console.warn("No data found in localStorage under userData.");
        return; // Handle this situation as needed
      }

      try {
        const decryptedUserData = decrypt(storedEncryptedUserData);

        // Validate the structure of decrypted data
        if (decryptedUserData && decryptedUserData.userId) {
          setUser(decryptedUserData);
        } else {
          console.error(
            "Decrypted data is empty or invalid:",
            decryptedUserData
          );
        }
      } catch (error) {
        console.error("Decryption failed:", error);
      }
    }
  }, []); // Run this effect only once on mount

  const saveUser = (user) => {
    // Ensure the userData is valid before saving
    if (user && user.userId) {
      const encryptedUserData = encrypt(user);
      localStorage.setItem("userData", encryptedUserData);
      setUser(user);
    } else {
      console.error("User data is empty or invalid:", user);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser: saveUser }}>
      {children}
    </UserContext.Provider>
  );
};
